// ★內頁 不需要 @include style.sass(layout)已經有
// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：內頁共用元件
//		- HTML Tag
//		- 表單(Form)相關元件
//		- 修改框架/套件
//		- keyframes

// --------------------------------------------------------

* {
	// safari
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	@at-root .android * {
		-webkit-tap-highlight-color: transparent;
	}

	&:focus {
		outline: none;
	}
}

input,
textarea,
button,
select,
a,
div {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Tag */

html,
body {
	overflow-x: hidden;
	overflow-y: auto;
}

body {
	letter-spacing: ps-letter-spacing(50);
	background: $bg-color $bg-gradient;

	@include breakpoint(small only) {
		font-size: rem-calc(12);
	}
}

img {
	// pointer-events: none;
}

a,
button,
.button {
	&:focus {
		outline: none;
	}
}

button {
	cursor: pointer;
}

/* iOS 15.1: <button>物件，會蓋過 <body> 設定的顏色為 -apple-system-blue 為預設顏色，
	如果<button>物件在後續樣式上沒有做顏色的變更，就會取代為蘋果定義的藍色
	解決方式 => 強制變更預設顏色
*/
button {
	color: #000;
}

iframe {
	border: 0 none;

	&:focus {
		outline: none;
	}
}

// H1 - H6 設定
@each $size, $headers in $header-styles {
	@include breakpoint(small only) {
		@each $header, $header-defs in $headers {
			// $font-size-temp: 1rem;
			#{$header},
			.#{$header} {
				// $font-size-temp: rem-calc(map-get($header-defs, font-size));
				// font-size: $font-size-temp * 0.9;

				// line-height: 1.2;
			}
		}
	}
}

.p {
	margin-bottom: 1rem;
	font-size: inherit;
	line-height: 1.5;
	text-rendering: optimizeLegibility;
}

// --------------------------------------------------------

/* 反選顏色 | Selection */
$selection-text-color: $white; // 反選的文字顏色
$selection-bg-color: lighten($black, 5%); // 反選的背景顏色

@mixin selection-color($text-color, $bg-color) {
	::selection {
		color: $text-color;
		background: $bg-color;

		// 當瀏覽器Tab非Focus狀態
		&:window-inactive {
			color: #323232;
			background: #c5c5c5;
		}
	}

	// Firefox版本: https://css-tricks.com/window-inactive-styling/#comment-92675
	// 但因為gulp-ruby-sass看不懂導致編譯錯誤(Invalid CSS)，所以無法使用
	// :-moz-window-inactive ::-moz-selection
	// 	color: #323232
	// 	background: #C5C5C5
}

// No Selection | 無法反選
// https://stackoverflow.com/a/4407335/11240898
// mixin 移動至 _variable_mixin_function.scss

// +selection-color(反選的文字顏色, 反選的背景顏色)
@include selection-color($selection-text-color, $selection-bg-color);

// 無法反選 | No Selection
.no-selection {
	@include selection-none;
}

// --------------------------------------------------------

// Custom Scroll Bar
// Mixin 移動到 src\sass\layout\_variable_mixin_function.scss
// 需使用則開啟下列樣式
@include breakpoint-hover {
	@include custom-scroll-bar($black);

	.custom-scrollbar {
		@include custom-scroll-bar(#cccccc);
	}

	.custom-scrollbar-black {
		@include custom-scroll-bar($black);
	}
}

// --------------------------------------------------------

/* Text */

.word-break {
	word-break: break-word;
}

.text-em {
	&,
	a {
		color: $primary-color;
	}
}

.text-black {
	&,
	a {
		color: $primary-black;
	}

	&-hover {
		&,
		a {
			&,
			&:hover,
			&:focus {
				color: $primary-black;
			}

			@include breakpoint-hover {
				&:hover,
				&:focus {
					color: $primary-black;
				}
			}
		}
	}
}

// 在白底上的灰字
.text-tip-light {
	color: $gray-dark1;
}

// 在灰底上的灰字
.text-tip {
	color: $gray-dark2;
}

.text-disabled {
	color: #b5b5b5;
	cursor: not-allowed;
}

.error__txt {
	font-size: rem-calc(12);
	letter-spacing: rem-calc(0.6);
	color: $status-error;
}

.text-thin {
	font-weight: 300;
}

.text-medium {
	font-weight: 500;
}

.text-semi-bold {
	font-weight: 600;
}

.text-bold {
	font-weight: bold;
}

.text-underline {
	text-decoration: underline;

	&-hover {
		@include breakpoint-hover {
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}

// --------------

// 文字溢行/文字點點點
%clamp {
	--line-height: 1.5;

	position: relative;
	display: block;
	display: -webkit-box;
	overflow: hidden;
	max-width: 100%;
	line-height: var(--line-height);
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.clamp {
	&,
	&-1 {
		@extend %clamp;

		display: block;
		max-width: 100%;
		white-space: nowrap;
	}

	@function limit-height-get($i, $line-height) {
		@return calc(1em * #{$line-height} * #{$i});
	}

	@for $i from 2 through 6 {
		&-#{$i} {
			@extend %clamp;

			-webkit-line-clamp: $i;
			max-height: limit-height-get($i, 1.6);
			max-height: limit-height-get($i, var(--line-height));
		}
	}
}

// --------------------------------------------------------

/* Image */

.img-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.img-rel {
	position: relative;
}

.img-deco {
	position: absolute;
	top: 0;
	left: 0;
}

/* object-fit */
// Fix the dimensions of an image and scale
// Default size: 1:1
//
// How to change size
// $imgWidth: // img width
// $imgHeight: // img height
// .object-fit:before{padding-top: round($imgHeight / $imgWidth * 100%)}
// round: round the number rounding
%object-fit-standard {
	position: relative;
	display: block; // Fixed <picture> bug
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}
}

%object-fit-cover {
	img {
		object-fit: cover;
		background-size: cover;
	}
}

%object-fit-contain {
	background-position: center center;
	background-size: contain;

	img {
		object-fit: contain;
		background-size: contain;
	}
}

.object-fit {
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	// 沒有內層(before)
	// &--wrap {
	// 	@extend %object-fit-standard;
	// 	@extend %object-fit-cover;

	// 	&::before {
	// 		display: none;
	// 	}
	// }

	// 應對不同比例的照片
	&-contain {
		@extend %object-fit-standard;
		@extend %object-fit-contain;
	}
}

// --------------------------------------------------------

/* Form */

%input-global {
	border-color: $gray-dark1;
	box-shadow: none;

	&:focus {
		box-shadow: none;
	}

	@include breakpoint-hover {
		&:hover,
		&:focus {
			border-color: $primary-black;
		}
	}
}

%input-height {
	height: rem-calc(44);
}

#{text-inputs()} {
	@extend %input-global;
	@extend %input-height;
}

input {
	&[type='date'] {
		// 隱藏預設右側月曆Icon
		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none;
		}

		@include breakpoint-hover {
			cursor: pointer;
		}
	}

	// UAT245: 修正 iOS 15 後日期欄位會置中問題
	// Ref: https://simplernerd.com/js-align-text-left-ios-date-input/
	&[type='date'],
	&::-webkit-date-and-time-value {
		text-align: left;
		text-align: -webkit-left;
	}
}

select {
	@extend %input-global;
	@extend %input-height;

	@include select-style; // 寫於 _variable_mixin_function
}

textarea {
	@extend %input-global;

	@include custom-scroll-bar(#d4d3d1, $white);

	margin-bottom: 0;
	min-width: 100%;
	min-height: rem-calc(220);
	resize: vertical;

	@include breakpoint(large) {
		min-height: rem-calc(220);
	}
}

label {
	cursor: pointer;
	font-size: inherit;
}

.input {
	margin-bottom: 0;

	&-group {
		position: relative;
		margin-bottom: 0;

		+ .input-group {
			margin-top: rem-calc(20);
		}
	}

	@extend %input-global;
	@extend %input-height;
}

// Icon(input 內的，absolute)
.has-icon {
	@at-root %input-icon-before {
		.input,
		.select {
			padding-left: rem-calc(50);
		}
	}

	@at-root %input-icon-after {
		.input,
		.select {
			padding-right: rem-calc(50);
		}
	}

	&-before {
		@extend %input-icon-before;
	}

	&-after {
		@extend %input-icon-after;
	}

	&-before-after {
		@extend %input-icon-before;
		@extend %input-icon-after;
	}
}

// 前面的 Icon
.input-icon-before {
	position: absolute;
	top: 50%;
	left: rem-calc(13);
	font-size: rem-calc(20);
	color: #a1a1a1;
	transform: translateY(-50%);

	~ #{text-inputs()},
	~ .input,
	~ .select {
		padding-left: rem-calc(50);
	}
}

// 後面的 Icon
.input-icon-after {
	--size: 2.75rem;

	position: absolute;
	top: 50%;
	right: 0;
	width: calc(var(--size) * 1.09091);
	height: var(--size);
	font-size: 1.5rem;
	text-align: center;
	transform: translateY(-50%);

	button {
		width: calc(var(--size) * 1.09091);
		height: var(--size);
	}

	~ #{text-inputs()},
	~ .input,
	~ .select {
		padding-left: rem-calc(50);
	}

	@include breakpoint-hover {
		@at-root %has-icon-hover {
			transition: opacity 0.3s;

			&:hover,
			&:focus {
				opacity: 0.5;
			}
		}

		@include bem-tag {
			@extend %has-icon-hover;
		}

		@include bem-tag(button) {
			@extend %has-icon-hover;
		}

		&[role='button'] {
			@extend %has-icon-hover;

			cursor: pointer;
		}
	}
}

.select {
	margin-bottom: 0;

	&-box {
		display: block;
	}
}

.custom-select {
	$root: &;

	--color: #{$primary-black};
	--bg-color: #{$white};
	--hover-color: var(--color);
	--hover-bg-color: #{$hover-bg};
	--active-color: #{$white};
	--active-bg-color: #{$primary-black};

	position: relative;

	&__selected {
		@extend %input-height;
		@include form-select;
		@include select-style; // 寫於 _variable_mixin_function

		width: 100%;
		text-align: left;
		border-color: $gray-dark1;

		&.hover {
			border-color: #000;
		}

		@include breakpoint-hover {
			transition: border-color 0.3s;

			&:hover {
				border-color: #000;
			}
		}
	}

	// <ul>
	&__list {
		margin: 0;
		padding: 0;
		width: 100%;
		max-width: none;
		background: var(--bg-color);
		border: $dropdown-border !important;
		list-style: none;
	}

	// <a>
	&__link {
		display: block;
		padding: rem-calc(7 8);
		font-size: rem-calc(14);
		color: var(--color);

		&.is-active {
			color: var(--active-color);
			background: var(--active-bg-color);
			pointer-events: none;
		}

		&:hover,
		&:focus {
			color: var(--hover-color);
			background: var(--hover-bg-color);
		}
	}
}

// --------------------------------------------------------
// Form
.form {
	$this: &;

	&__group {
		position: relative;
		margin-bottom: rem-calc(20);
		padding: rem-calc(0 20);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(45);
		}

		&.ctrl {
			@include breakpoint(medium) {
				margin-top: rem-calc(60);
			}
		}

		&-title {
			display: block;
			margin-bottom: rem-calc(7);
			font-size: rem-calc(14);
			line-height: 1.5;
			letter-spacing: rem-calc(0.7);

			&__label {
				display: inline-block;
			}

			&__button-icon {
				font-size: percentage(20 / 14);
				transform: translateY((20 - 14) / 20 / 2 * 100%);

				&.circle-box {
					--size: #{rem-calc(20)};

					display: inline-block;
					margin-left: rem-calc(4);

					.be-icon {
						font-size: 1em;

						&::before {
							margin: 0;
						}
					}
				}

				@include breakpoint-hover {
					&.circle-box {
						&:hover,
						&:focus {
							--color: #{$primary-black};
							--bg-color: transparent;
						}
					}
				}
			}
		}

		#{$this} {
			&__tip {
				margin-top: rem-calc(10);
				margin-bottom: 0;
			}
		}
	}

	&__tip {
		display: block;
		margin-bottom: rem-calc(30);
		font-size: rem-calc(12);
		letter-spacing: ps-letter-spacing(20);

		a {
			color: currentColor;
		}

		p,
		.p {
			margin-bottom: 0;
		}
	}

	&__label {
		+ #{$this}__label {
			margin-top: rem-calc(20);
		}
	}

	// disabled
	.is-disabled {
		.is-hide {
			pointer-events: none;
			opacity: 0;
		}
	}

	@at-root %error-show {
		display: block;
		opacity: 1;
	}

	// error
	.is-error {
		.select,
		.input,
		.checkmark,
		textarea,
		.select2-container--default .select2-selection--multiple,
		.custom-select__select {
			border-color: $status-error;
		}

		.error__txt,
		~ .error__txt {
			@extend %error-show;
		}

		.has-icon {
			> {
				.be-icon {
					color: $status-error;
				}
			}
		}
	}

	.error__txt {
		display: none;
		margin-top: rem-calc(8);
		margin-bottom: 0;
		// font-size: rem-calc(12);
		// letter-spacing: rem-calc(0.6);
		// color: $status-error;
		opacity: 0;

		&.is-error {
			@extend %error-show;
		}
	}
}

// -------------

/* Checkbox */

// ※使用：將 .checkbox 增加於 input 項目

// Checkbox & Radio Button形式
$checkbox-main-color: $primary-black;
$checkbox-label-color: var(--color-checkbox);
$checkbox-bg-color: transparent;
$checkbox-border-color: var(--color-checkbox);
$checkbox-border-radius: 0;
$checkbox-hover-label-color: var(--color-checkbox);
$checkbox-hover-border-width: 2px;
$checkbox-hover-border-color: var(--color-checkbox);
$checkbox-hover-bg-color: rgba($primary-black, 0.05);
$checkbox-checked-checker-color: #fff;
$checkbox-checked-color: var(--color-checkbox);
$checkbox-checked-bg-color: $checkbox-bg-color;
$checkbox-checked-border-color: var(--color-checkbox);
$checkbox-checked-hover-border-color: var(--color-checkbox);
$checkbox-checked-hover-bg-color: rgba($primary-black, 0.05);
$checkbox-checked-hover-color: var(--color-checkbox);
$checkbox-disable-border-color: #dedede;
$checkbox-disable-bg-color: #ebedf0;

// Checker
$checkbox-checker-border-width: 3px;
$checkbox-checker-size: 12px;

$checkbox-size: 20px;
$radio-size-inside: 10px;

// $build-all是否生成所有相關樣式
@mixin checkbox-label($build-all: true) {
	position: absolute;
	opacity: 0;
	cursor: pointer;

	@if $build-all == true {
		+ label {
			--padding-x: #{rem-calc(0)};
			--padding-y: #{rem-calc(4.5)};
			--padding-space: #{rem-calc(10)}; // 文字跟 Checkbox 方塊的距離
			--padding-left: calc(var(--size) + var(--padding-space));
			--size: #{$checkbox-size};
			--size-checker: #{$checkbox-checker-size};
			--color-checkbox: var(--color, #{$checkbox-main-color});
			--border-radius: #{$checkbox-border-radius};

			position: relative;
			margin-left: 0;
			padding: var(--padding-y) var(--padding-x);
			padding-left: var(--padding-left);
			font-size: 1rem;
			line-height: 1.2;
			color: $checkbox-label-color;
			word-break: break-word;

			// 外框
			&::before {
				content: '';
				position: absolute;
				top: calc(var(--padding-y) + 0.6em); // light-height 1.2 / 2
				left: 0;
				display: inline-block;
				width: var(--size);
				height: var(--size);
				background-color: $checkbox-bg-color;
				border: 1px solid $checkbox-border-color;
				border-radius: var(--border-radius);
				transform: translateY(-50%);
				// transition: all 0.3s;
			}

			// :Checked 樣式
			&::after {
				content: '';
				position: absolute;
				top: calc(var(--padding-y) + 0.6em); // light-height 1.2 / 2
				left: calc((var(--size) - var(--size-checker)) / 2);
				margin-top: calc(var(--size-checker) / -2);
				width: var(--size-checker);
				height: var(--size-checker);
				background: var(--color-checkbox);
				opacity: 0;
				transform: scale(0);
				transition: all 0.3s;
			}

			// ★★★專案
			@include breakpoint(small only) {
				font-size: rem-calc(12);
			}
		}

		// Checked
		&:checked {
			+ label {
				// :Checked 樣式
				&::after {
					opacity: 1;
					transform: scale(1);
					transition-timing-function: cubic-bezier(0.52, -0.5, 0.5, 2);
				}
			}
		}

		// Not Checked & Hover
		&:not(:checked) {
			+ label {
				@include breakpoint-hover {
					&:hover {
						&::before {
							background: $checkbox-hover-bg-color;
							border-width: $checkbox-hover-border-width;
						}

						&::after {
							opacity: 0.5;
							transform: scale(0.5);
						}
					}
				}
			}
		}

		&:focus {
			+ label {
				&::before {
					background: $checkbox-hover-bg-color;
					border-width: 2px;
					border-color: $checkbox-hover-border-color;
				}
			}
		}

		// Disable
		&:disabled {
			+ label {
				cursor: not-allowed;

				&::before {
					background: $checkbox-disable-bg-color;
					border-color: $checkbox-disable-border-color;
				}

				@include breakpoint-hover {
					&:hover {
						&::before {
							background: $checkbox-disable-bg-color;
							border-color: $checkbox-disable-border-color;
						}
					}
				}
			}
		}

		// // Checked Info
		// ~ .checked-info {
		// 	display: none;
		// }
	}
}

// Checkbox
.checkbox {
	@include checkbox-label;

	&-hide-only {
		@include checkbox-label(false);
	}
}

// ------
// Radio
$checkbox-size: 20px;

.radiobox {
	@include checkbox-label;

	+ label {
		--border-radius: 99px;

		&::after {
			border-radius: var(--border-radius);
		}
	}

	// Checked
	&:checked {
		+ label {
			cursor: default !important;
		}
	}
}

// input[type="checkbox"]
// 	@extend .checkbox

// input[type="radio"]
// 	@extend .radiobox

// --------------------------------------------------------

// checkbox 外層
.checkbox-box {
	position: relative;
}

//  比較相關
.compare-box {
	@include breakpoint(small only) {
		label {
			--padding-space: #{rem-calc(6)};

			font-size: rem-calc(12);
		}
	}
}

// --------------------------------------------------------

/* Table */
%td {
	padding: rem-calc(9 + 5);
	padding-top: rem-calc(9 + 5);
}

tbody {
	td,
	th {
		@extend %td;
	}
}

tbody,
tfoot,
thead {
	background: transparent;
}

tbody {
	tr {
		border-top: 1px solid $primary-black;
		border-top-width: thin;

		&:last-child {
			border-bottom: 1px solid $primary-black;
			border-bottom-width: thin;
		}

		&:nth-child(even) {
			background: transparent;
		}

		&:hover {
			background: $white;
		}
	}

	td,
	th {
		@include breakpoint(xlarge) {
			padding-right: rem-calc(50);
			padding-left: rem-calc(50);
		}
	}
}

table {
	border-top: 2px solid $primary-black;
}

@import '../components/table-wrapper';

// --------------------------------------------------------

/* Flex */

// --------------------------------------------------------

.list-bottom {
	--margin-y: #{rem-calc(30)};

	margin-top: var(--margin-y);
	margin-bottom: var(--margin-y);

	@include breakpoint(medium) {
		--margin-y: #{rem-calc(60)};
	}
}

/* Loading */
.loading-dot {
	--margin-y: #{rem-calc(25)};

	position: relative;
	margin: var(--margin-y) 0;
	opacity: 0.25;

	&__dot {
		--size: #{rem-calc(8)};
		--margin-x: calc(var(--size) / 2);
		--dot-color: #{$primary-black};

		position: relative;
		display: block;
		margin: 0 auto;
		width: var(--size);
		height: var(--size);
		color: var(--dot-color);
		background-color: var(--dot-color);
		border-radius: 9px;
		transform: scale(1.25, 1.25);
		animation: dotStretching 2s infinite ease-in;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			display: inline-block;
			width: var(--size);
			height: var(--size);
			color: currentColor;
			background-color: currentColor;
			border-radius: 9px;
			animation: dotStretchingBefore 2s infinite ease-in;
		}

		&::after {
			animation: dotStretchingAfter 2s infinite ease-in;
		}
	}
}

// Ref: https://codepen.io/nzbin/pen/GGrXbp?editors=0100
@keyframes dotStretching {
	0% {
		transform: scale(1.5, 1.5);
	}

	50%,
	60% {
		transform: scale(0.8, 0.8);
	}

	100% {
		transform: scale(1.5, 1.5);
	}
}

@keyframes dotStretchingBefore {
	0% {
		transform: translate(0) scale(0.7, 0.7);
	}

	50%,
	60% {
		transform: translate(-300%) scale(1, 1);
	}

	100% {
		transform: translate(0) scale(0.7, 0.7);
	}
}

@keyframes dotStretchingAfter {
	0% {
		transform: translate(0) scale(0.7, 0.7);
	}

	50%,
	60% {
		transform: translate(300%) scale(1, 1);
	}

	100% {
		transform: translate(0) scale(0.7, 0.7);
	}
}

// --------------------------------------------------------

/* Section */
// .off-canvas-content {
// 	display: flex;
// 	flex-direction: column;
// 	min-height: 100vh;
// }

.main-wrapper {
	padding-top: var(--hd-height-fixed);

	// 為了讓 Footer 能一直置底
	--ft-height: #{rem-calc(494)};

	min-height: calc(100vh - var(--ft-height));

	@include breakpoint(medium) {
		--ft-height: #{rem-calc(275)};
	}

	@include breakpoint(large) {
		--ft-height: #{rem-calc(168)};
	}
}

.page-title {
	margin-top: rem-calc(20);
	margin-bottom: rem-calc(20);
	text-align: center;
	text-shadow: 0px 3px 4.2px rgba($black, 0.05);

	@include breakpoint(medium) {
		margin-top: rem-calc(30);
		margin-bottom: rem-calc(40);
	}

	@include breakpoint(large) {
		margin-top: rem-calc(60);
		margin-bottom: rem-calc(70);
	}
}

// 區塊內容
.sec {
	--padding-y: #{rem-calc(50)};

	padding-top: var(--padding-y);
	padding-bottom: var(--padding-y);

	// &:first-of-type {
	// 	padding-top: rem-calc(46);
	// }

	@at-root %sec-large {
		--rate: 1.5; // large 放大倍率

		@include breakpoint(large) {
			--rate: 2; // large 放大倍率
		}
	}

	&.pd-large {
		@extend %sec-large;

		padding-top: calc(var(--padding-y) * var(--rate));
		padding-bottom: calc(var(--padding-y) * var(--rate));

		&-large {
			@extend %sec-large;

			@include breakpoint(medium down) {
				--rate: 1;
			}
		}
	}

	// padding-top 較大
	&.pt-large {
		@extend %sec-large;

		padding-top: calc(var(--padding-y) * var(--rate));
	}

	&.pb-large {
		@extend %sec-large;

		padding-bottom: calc(var(--padding-y) * var(--rate));
	}
}

.sec-block {
	&:not(:last-child) {
		margin-bottom: rem-calc(40);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(100);
		}
	}
}

// 標題
.sec-title-box {
	margin-bottom: rem-calc(36);
	text-align: center;

	@include breakpoint(large) {
		margin-bottom: rem-calc(42);

		&.small {
			margin-bottom: rem-calc(24);
		}

		&.large {
			margin-bottom: rem-calc(90);
		}
	}

	&__title {
		font-weight: $font-weight-b;
		text-shadow: 0 0 24px rgba($black, 0.08);
		word-break: break-word;
	}

	&.text-left {
		text-align: left;
	}
}

// 內頁上方選單
.page-nav {
	$root: &;

	--padding-y: #{rem-calc(15)};
	--font-size: #{rem-calc(14)};
	--bg: #{$white};
	--box-shadow: #{$box-shadow-panel};

	position: relative;
	z-index: 900;
	top: 0;
	width: 100%;
	opacity: 0;
	animation: fade 0.5s 0.7s forwards;

	@include breakpoint(medium down) {
		--padding-x: #{rem-calc(15)};
	}

	@include breakpoint(large only) {
		--font-size: #{rem-calc(16)};
	}

	@include breakpoint(xlarge) {
		--font-size: #{rem-calc(20)};
	}

	&__cont {
		background-color: var(--bg);
		box-shadow: var(--box-shadow);
		transition: all $hd-tsd;

		@include breakpoint(medium down) {
			padding-top: var(--padding-y);
			padding-bottom: var(--padding-y);
		}

		@include breakpoint(large) {
			padding-top: var(--padding-y);
			padding-bottom: var(--padding-y);
		}

		// 白色遮罩，避免固定時回到最上方，Header恢復原本的高度會需要一點時間，造成中間的缺陷
		// 補足空白
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50vh; // 高度隨意抓的
			pointer-events: none;
			background: var(--bg);
		}
	}

	&__scroll-wrapper {
		position: relative;

		@at-root %page-nav-mask {
			--dir: to right;

			content: '';
			position: absolute;
			z-index: 5;
			top: 0;
			display: none;
			width: 50px;
			height: 100%;
			background: linear-gradient(var(--dir), rgba($white, 1) 0%, rgba($white, 0) 100%);
			pointer-events: none;
			opacity: 0;
		}

		&::before {
			@extend %page-nav-mask;

			left: 0;
		}

		&::after {
			@extend %page-nav-mask;

			--dir: to left;

			right: 0;
		}

		&.has-scroll {
			&::before,
			&::after {
				display: block;
			}
		}

		&:not(.is-start) {
			&::before {
				opacity: 1;
			}
		}

		&:not(.is-end) {
			&::after {
				opacity: 1;
			}
		}
	}

	&__scroll-area {
		overflow-y: auto;
		margin: calc(var(--padding-y) * -1) 0;
		padding: var(--padding-y) 0;
		-webkit-overflow-scrolling: touch;
	}

	&__list {
		--gutter: #{rem-calc(25)};

		margin: 0;
		// margin: 0 calc(var(--gutter) / -2);
		text-align: center;
		white-space: nowrap;
		list-style: none;

		// 為了對齊
		@include breakpoint(small only) {
			padding-right: rem-calc(2);
			padding-left: rem-calc(2);
		}

		@include breakpoint(large) {
			// 串接程式測試站因為壓縮HTML，導致子層使用display inline-block 沒有間隔，為了要把間隔移除 => 改用 display flex
			// UAT 123 - 客戶提出修正要求
			// 21111: 因為過小的手機板會有問題
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@include breakpoint(xlarge) {
			--gutter: #{rem-calc(90)};
		}
	}

	&__item {
		display: inline-block;
		margin: 0 calc(var(--gutter) / 2);

		// Active 紅字
		&.is-active {
			#{$root} {
				&__link {
					color: $primary-color;

					&::before {
						transform: scaleX(1);
					}

					@include breakpoint-hover {
						&:focus {
							text-shadow: 0 0 1px $primary-color;
						}
					}
				}
			}
		}
	}

	&__link {
		--padding-y: #{rem-calc(5)};

		position: relative;
		display: block;
		padding: var(--padding-y) 0;
		font-size: var(--font-size);
		font-weight: bold;
		color: rgba($primary-black, 0.5);

		// Active Bar
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: currentColor;
			transform: scaleX(0);
			transition: transform 0.3s;
		}

		&.is-active {
			color: $primary-color;

			&::before {
				transform: scaleX(1);
			}
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				color: $primary-color;

				&::before {
					// transform: scaleX(1);
				}
			}
		}
	}

	// 固定
	&.is-fixed {
		height: rem-calc(40); // 原本的高度

		#{$root} {
			&__cont {
				position: fixed;
				top: var(--hd-height);
				left: 0;
				width: 100%;

				@at-root #header.is-collapse {
					~ .off-canvas-content {
						#{$root} {
							&__cont {
								background: $white;
							}
						}
					}
				}
			}
		}
	}

	&--fade-out {
		&.is-fixed {
			#{$root} {
				&__cont {
					opacity: 0;
					transform: translateY(-100%);
				}
			}
		}
	}

	// 選單下滑後
	// @at-root html.is-collapse {
	// 	#{$root} {
	// 		@include breakpoint(small only) {
	// 			--padding-y: #{rem-calc(4)};
	// 		}

	// 		@include breakpoint(medium) {
	// 			--padding-y: #{rem-calc(10)};
	// 		}
	// 	}
	// }
}

@mixin scrollBarDetect {
	$unit-menu-bg: #f0f0f0;

	--gradient-color: #{hex-to-rgb($unit-menu-bg)};

	position: relative;

	// 滾動提示遮罩
	&::before,
	&::after {
		content: '';
		position: absolute;
		z-index: 5;
		top: 0;
		display: none;
		width: #{rem-calc(50)};
		height: 100%;
		background: linear-gradient(to right, rgba(var(--gradient-color), 0) 0%, rgba(var(--gradient-color), 1) 100%);
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.3s;
		pointer-events: none;

		@include breakpoint(medium) {
			width: #{rem-calc(100)};
		}
	}

	// 左遮罩
	&::before {
		left: 0;
		transform: scale(-1);
	}

	// 右遮罩
	&::after {
		--gradient-color: #{hex-to-rgb(#f6f6f6)};

		right: 0;
	}

	&.has-scroll {
		&::before,
		&::after {
			display: block;
		}
	}

	&:not(.is-scroll-start) {
		&::before {
			opacity: 1;
		}
	}

	&:not(.is-scroll-end) {
		&::after {
			opacity: 1;
		}
	}
}

.page-sub-nav {
	$root: &;

	--border-width: #{rem-calc(3)};

	margin-bottom: rem-calc(30);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(50);
	}

	&__cont {
		position: relative;

		// border-bottom(黑線)
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: var(--border-width);
			background: $primary-black;
		}
	}

	&__list {
		--margin-x: #{rem-calc(16) / 2};

		display: flex;
		margin: 0 calc(var(--margin-x) * -1);
		list-style: none;
		white-space: nowrap;

		@include breakpoint($xs) {
			--margin-x: #{rem-calc(32) / 2};
		}

		@include breakpoint(xlarge) {
			--margin-x: #{rem-calc(60) / 2};
		}
	}

	&__item {
		margin: 0 var(--margin-x);

		&.is-active {
			#{$root} {
				&__link {
					position: relative;
					opacity: 1;
					color: $primary-color;
					pointer-events: none;

					&::before {
						content: '';
						position: absolute;
						// bottom: calc(var(--border-width) * -1);
						bottom: 0;
						left: 0;
						width: 100%;
						height: var(--border-width);
						background: currentColor;
					}
				}
			}
		}
	}

	&__link {
		--padding-y: #{rem-calc(16)};

		display: block;
		padding-top: var(--padding-y);
		padding-bottom: var(--padding-y);
		font-size: rem-calc(12);
		color: $primary-black;
		opacity: 0.5;

		// 移除 Foundation 樣式
		@include bem-tag {
			&:hover,
			&:focus {
				color: $primary-black;
			}
		}

		@include breakpoint($xs) {
			font-size: rem-calc(14);
		}

		@include breakpoint(large) {
			--padding-y: #{rem-calc(18)};

			font-size: rem-calc(24);
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				opacity: 1;
			}

			@include bem-tag {
				&:hover,
				&:focus {
					color: $primary-color;
				}
			}
		}

		small {
			display: block;
			font-size: percentage(16 / 24);
		}
	}

	&.active-can-click {
		#{$root} {
			&__item {
				&.is-active {
					#{$root} {
						&__link {
							pointer-events: auto;
						}
					}
				}
			}
		}
	}

	&.average {
		#{$root} {
			&__scroll-area {
				overflow-x: auto;
				overflow-y: hidden;
			}
		}

		@include breakpoint(medium) {
			#{$root} {
				&__list {
					--margin-x: 0;
				}

				&__item {
					text-align: center;
				}
			}
		}

		@include breakpoint(medium only) {
			#{$root} {
				&__item {
					--width: #{percentage(1/3.5)};

					width: var(--width);
					flex: 0 0 var(--width);
				}
			}
		}

		@include breakpoint(large) {
			#{$root} {
				&__item {
					flex: 1;
				}
			}
		}
	}

	&.has-scroll {
		@include scrollBarDetect;

		// &::before,
		// &::after {
		// 	height: calc(100% - 3px);
		// }
	}
}

// 麵包屑
.breadcrumbs {
	margin-top: rem-calc(25);
	margin-bottom: rem-calc(25);

	a {
		color: $primary-black;

		@include breakpoint-hover {
			&:focus {
				text-decoration: underline;
			}
		}
	}

	li {
		font-size: rem-calc(14);
		text-transform: none;
		color: #5e5e5e;

		&:not(:last-child) {
			&::after {
				content: '>';
				margin-right: rem-calc(4);
				margin-left: rem-calc(2);
				color: $primary-black;
			}
		}
	}

	&-wrapper {
		margin-top: rem-calc(-50);
	}

	&.has-max-width-medium-up {
		@include breakpoint(medium) {
			[itemprop='itemListElement']:last-child {
				[itemprop='name'] {
					max-width: 30em;
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
}

// 頁面Banner
.page-banner {
	$root: &;

	&__img {
		background-position: center center;
		background-size: cover;

		&::before {
			@include before-img-size(800, 360, true);

			@include breakpoint(medium) {
				@include before-img-size(1920, 360);
			}
		}
	}

	+ .sec {
		.breadcrumbs-wrapper {
			margin-top: 0;
		}
	}

	// about 有字版
	&.with-text {
		position: relative;

		#{$root}__img {
			&::before {
				@include before-img-size(320, 192, true);

				@include breakpoint(medium) {
					@include before-img-size(1920, 596);
				}

				@include breakpoint(medium) {
					padding-top: rem-calc(596);
				}
			}
		}

		#{$root}__info {
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 50%;
			width: 100%;
			padding: rem-calc(20 15);
			transform: translate(-50%, -50%);
			color: $white;
		}

		#{$root}__title {
			margin-bottom: rem-calc(8);
			letter-spacing: rem-calc(3);

			@include breakpoint(medium) {
				margin-bottom: rem-calc(22);
			}
		}

		#{$root}__desc {
			margin-bottom: 0;
			font-weight: 600;
			letter-spacing: rem-calc(0.9);

			@include breakpoint(medium) {
				font-size: rem-calc(18);
			}
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($primary-black, 0.4);
		}
	}

	// 增加動畫
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.page-banner__title', '.page-banner__desc';

	&.js-ani {
		@include set-animate($animate-el);

		&.is-animated {
			@include set-animated($animate-el);
		}
	}
	/* stylelint-enable */
}

// 頁面最底灰色區
.page-foot {
	--padding-y: #{rem-calc(50)};

	background: $gray-light1;
	// padding: 50%;
}

.svc-list {
	$root: &;

	display: flex;
	justify-content: space-around;
	margin: 0 auto;
	list-style: none;

	@include breakpoint(large) {
		justify-content: space-between;
		max-width: rem-calc(500); // 要設置最寬值，來平均分配
	}

	&__item {
		flex: 1;
	}

	&__link {
		display: block;
		font-weight: $font-weight-m;
		text-align: center;
		color: $primary-black;

		@include breakpoint(small only) {
			font-size: rem-calc(12);
		}

		// Link 為 <a>
		@include bem-tag {
			// 更改 Foundation Hover 基礎顏色
			&:hover,
			&:focus {
				color: $primary-black;
			}

			@include breakpoint-hover {
				&:hover,
				&:focus {
					.circle-box {
						@include btn-border-hover;
					}
				}
			}
		}

		// Link 為 <div>
		@include bem-tag(div) {
			.circle-box {
				--bg-color: transparent;
				--box-shadow: none;

				@include breakpoint-hover {
					&:hover {
						--space-expand: 0px; // 一定要有單位
						--color: #{$primary-black};
						--bg-color: transparent;
					}

					&::after {
						display: none;
					}
				}
			}
		}
	}

	&__title {
		font-weight: $font-weight-b;
	}

	&__desc {
		margin-top: rem-calc(8);
		font-size: #{rem-calc(12)};

		@include breakpoint(medium) {
			font-size: #{rem-calc(14)};
		}

		a {
			color: $primary-black;

			@include breakpoint-hover {
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}

	.circle-box {
		--size: #{rem-calc(40)}; // 圓尺寸

		margin-right: auto;
		margin-left: auto;

		@include breakpoint(medium) {
			--size: #{rem-calc(66)}; // 圓尺寸
		}
	}

	&[data-layout='index'] {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 10px;
		max-width: none;
	}

	&[data-layout='contact'] {
		justify-content: space-between;
		// max-width: percentage($number: 8 / 12);
		max-width: rem-calc(954);

		#{$root} {
			&__item {
				width: percentage($number: 1 / 3);
			}
		}

		// 改為直排
		@include breakpoint(medium down) {
			display: inline-block;

			#{$root} {
				&__item {
					width: 100%;

					&:not(:last-child) {
						margin-bottom: rem-calc(10 + 15);
					}
				}

				&__link {
					display: flex;
				}

				&__text-box {
					display: flex;
					justify-content: center;
					flex: 1;
					flex-direction: column;
					text-align: left;
				}

				&__desc {
					margin-top: 0;
				}
			}

			.circle-box {
				flex: 0 0 var(--size);
				margin: var(--space-normal);
				margin-right: rem-calc(20);
			}
		}

		@include breakpoint(xlarge) {
			#{$root} {
				&__item {
					width: percentage($number: 2 / 10);
				}
			}
		}
	}
}

// 聯絡資訊外層
// → ul.svc-list(data-layout="contact") 的外層
.contact-info {
	@include breakpoint(medium down) {
		.grid-container {
			text-align: center;
		}
	}
}

// --------------------------------------------------------

// 區塊含有背景
.sec-bg {
	position: relative;
}

// 背景區域外層，一定要加 .sec-bg (為了設定 position relative)
.bg-box {
	$root: &;

	position: absolute;
	z-index: -10;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
	pointer-events: none;

	// 背景圖
	&__bg {
		position: absolute;
		z-index: -8;
		width: 100%;
		height: 100%;
		pointer-events: none;
		background-position: center center;
		background-size: cover;
	}

	// 需要設定底色，所以要限制 overflow hidden 情況
	&.has-overflow-visible {
		overflow: visible;
	}

	// 具有底色的樣式
	&.has-color {
		// 底色設定在 before，避免蓋過圖片
		&::before {
			content: '';
			position: absolute;
			z-index: -5;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&.has-mask-top {
		mask-image: linear-gradient(to top, $black 0%, transparent 200px);
	}
}

.bg {
	&--paper1 {
		// 底色
		// &::before {
		// 	background-color: #edf7fb;
		// }

		.bg-box {
			&__bg {
				&.loaded {
					background-image: url('../images/bg_paper.webp');

					@include webp(false) {
						background-image: url('../images/bg_paper.png');
					}

					@include breakpoint(medium down) {
						background-size: (1920px / 2);
					}
				}
			}
		}
	}

	&--paper2 {
		// 底色
		// &::before {
		// 	background-color: #edf7fb;
		// }

		.bg-box {
			&__bg {
				opacity: 0.4;

				&.loaded {
					background-image: url('../images/bg_paper2.webp');

					@include webp(false) {
						background-image: url('../images/bg_paper2.png');
					}

					@include breakpoint(medium down) {
						background-size: (1602px / 2);
					}
				}
			}
		}
	}
}

// --------------------------------------------------------

/* Foundation */

// 水平 Padding (Medium+ 避免左右寬度過窄)
.grid-container {
	// --padding-x: #{$gutter-s-half}; // 設定於:root

	@include breakpoint(medium) {
		// --padding-x: #{rem-calc(70)}; // 設定於:root

		padding-right: var(--padding-x);
		padding-left: var(--padding-x);
	}

	@include breakpoint(xlarge) {
		// --padding-x: #{rem-calc(100)}; // 設定於:root
	}
}

// Accordion ----
%accordion-title-before {
	@include font-icon-arrow-bottom;
}

.accordion {
	&-title {
		&::before {
			@extend %accordion-title-before;

			margin-top: rem-calc(-11);
			font-size: rem-calc(24);
			transition: transform 0.3s;
		}

		@include breakpoint-hover(false) {
			&:hover,
			&:focus {
				background: transparent;
			}
		}
	}

	&-item {
		&.is-active {
			.accordion-title {
				&::before {
					@extend %accordion-title-before;

					margin-top: rem-calc(-12);
					transform: rotate(180deg);
				}
			}
		}
	}
}
// [END] Accordion ----

// Close Button
.close-button {
	--size: #{rem-calc(50)};
	--size-cross: #{rem-calc(22)};
	--stroke-width: 1px;

	top: 0;
	right: 0;
	width: var(--size);
	height: var(--size);
	color: $primary-black;

	@at-root %close-button-before-after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		margin-left: -16px;
		margin-left: calc(var(--size-cross) / -2);
		width: var(--size-cross);
		height: var(--stroke-width);
		background: currentColor;
		transform: rotate(45deg);
	}

	&::before {
		@extend %close-button-before-after;
	}

	&::after {
		@extend %close-button-before-after;

		transform: rotate(135deg);
	}

	@include breakpoint(medium) {
		--size-cross: #{rem-calc(34)};
		--stroke-width: 2px;
	}
}

.reveal-overlay {
	z-index: 2000;
}

// Reveal
.reveal {
	@include custom-scroll-bar($black);

	background-color: #f6f6f6;
	border: 0 none;
	animation: reveal-fade 0.5s;

	&:focus {
		outline: none;
	}

	@keyframes reveal-fade {
		0% {
			opacity: 0;
			transform: translateY(-50px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes reveal-fade-from-right {
		0% {
			opacity: 0;
			transform: translateX(100%);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	@keyframes reveal-fade-from-left {
		0% {
			opacity: 0;
			transform: translateX(-100%);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	@keyframes reveal-fade-from-bottom {
		0% {
			opacity: 0;
			transform: translateY(100%);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.reveal-msg {
	$root: &;

	--padding-x: #{rem-calc(15)};
	--padding-y: #{rem-calc(50)};

	padding: var(--padding-y) var(--padding-x);
	padding-top: calc(var(--padding-y) + #{rem-calc(10)});
	overflow-x: hidden;

	@include breakpoint(small only) {
		left: $gutter-s-half;
		width: calc(100% - #{rem-calc($gutter-s)});
		height: auto;
		min-height: 0;
	}

	@include breakpoint(medium) {
		--padding-x: #{rem-calc(50)};
	}

	&__wrapper {
		@include breakpoint(small only) {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}

	&__cont {
		text-align: center;

		@include breakpoint(small only) {
			width: 100%;
		}
	}

	&__icon-box {
		@at-root %reveal-msg-icon-box {
			--size: #{rem-calc(68)};

			margin: 0 auto;
			margin-top: em-calc(-20 / 68);
			color: var(--color, currentColor);
		}

		@extend %reveal-msg-icon-box;

		margin-bottom: em-calc(-14 / 68);
		font-size: var(--size);

		&--circle {
			@extend %reveal-msg-icon-box;

			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: rem-calc(5);
			width: var(--size);
			height: var(--size);
			font-size: calc(var(--size) * #{(37 / 68)});
			border: 2px solid var(--color, currentColor);
			border-radius: 50%;

			.be-icon-fail {
				font-size: calc(var(--size) * #{(37 / 68 * (28 / 37))});
			}
		}
	}

	&__text-box {
		margin-bottom: em-calc(40 / 16);
		word-break: break-all;
		word-break: break-word;
	}

	&__title {
		margin-bottom: em-calc((27 + 8) / 24);
	}

	&__desc {
		letter-spacing: 0;
	}

	&__button-box {
		margin-top: em-calc(32 / 16);
	}

	&.msg-full {
		@include breakpoint(small only) {
			left: 0;
			width: 100%;
			height: 100%;
			min-height: 100%;
		}
	}

	&.msg-error {
		--color: #{$status-error};
	}

	&.msg-success {
		--color: #{$status-success};
	}

	// 關閉按鈕
	// 設計稿電腦版沒有，手機板顯示區域比較難關閉 => 顯示
	.close-button {
		@include breakpoint(medium) {
			display: none;
		}
	}
}

// Video
.reveal-video {
	$root: &;

	overflow: visible;
	padding: 0;
	height: auto;
	min-height: 0;
	background: transparent;
	border: 0 none;

	@include breakpoint(medium) {
		width: percentage(1440 / 1920);
		max-width: 1440px;
	}

	.responsive-embed {
		margin-bottom: 0;
	}

	.close-button--video {
		position: absolute;
		top: calc(var(--size) * -1 - #{rem-calc(30)});
		right: 5px;

		@include breakpoint(medium) {
			--size: #{rem-calc(60)};

			top: 0;
			right: -100px;
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				--bg-color: #{$white};

				color: $primary-black;
				transform: rotate(90deg);
			}
		}
	}
}
// [END] Reveal

// --------------------------------------------------------

// [START] Dropdown
.dropdown-pane {
	border: 0 none;
	box-shadow: $box-shadow-panel;
}
// [END] Dropdown

// --------------------------------------------------------

// [START] Tab (Foundation Component)
.tabs {
	background: transparent;
	border: 0 none;
}

.tabs-title {
	> a {
		padding: var(--padding-y) 0;
		color: $primary-black;
		opacity: 0.5;
		font-size: rem-calc(12);

		@include breakpoint($xs) {
			font-size: rem-calc(14);
		}

		@include breakpoint(large) {
			font-size: rem-calc(18);
		}

		@include breakpoint(xxlarge) {
			font-size: rem-calc(24);
		}

		&:focus,
		&[aria-selected='true'] {
			background: transparent;
		}

		&:hover {
			background: transparent;
		}
	}
}

.tabs-content {
	background: transparent;
	border: 0 none;
}

.tabs-panel {
	padding: 0;
}
// [END] Tab (Foundation Component)

// --------------------------------------------------------

// 具有Border & Border 間隙的物件（按鈕、圓型按鈕）
%btn-standard {
	color: var(--color);
	background: var(--bg-color);
}

%btn-border-item {
	@extend %btn-standard;

	--space-expand: 0px; // 單位要留著，Hover 後 space-normal 增加多少（Hover才進行調整）
	// ↑★一定要有單位，不然calc一開始會沒有作用
	--space-normal: #{rem-calc(5)}; // border 與實心部分的間隙（基本值）
	--space: calc(var(--space-normal) + var(--space-expand)); // border 與實心部分的間隙
	--color: #{$primary-black};
	--bg-color: #{$white}; // 背景顏色、Border 顏色
	--box-shadow: 0 0 8px #{rgba($black, 0.08)};

	position: relative;
	box-shadow: var(--box-shadow);

	// Before After
	@at-root %btn-border-border {
		content: '';
		position: absolute;
		top: calc(var(--space) * -1);
		left: calc(var(--space) * -1);
		width: calc(100% + var(--space) * 2);
		height: calc(100% + var(--space) * 2);
		border: 1px solid $border-color;
		border-radius: 99px;
	}

	// Border
	&::before {
		@extend %btn-border-border;

		box-shadow: var(--box-shadow);
	}

	// Hover Wave
	&::after {
		@extend %btn-border-border;

		display: none;
		opacity: 0;
	}

	// 更改 Foundation Hover 基礎顏色
	&:hover,
	&:focus {
		@extend %btn-standard;

		--color: #{$primary-black};
		--bg-color: #{$white};
	}

	@include breakpoint-hover {
		&,
		&::before {
			transition: all 0.3s;
		}

		&::after {
			display: block;
		}

		&:hover,
		&:focus {
			// 改為 @mixin，相關樣式搬移至 _variable_mixin_function
			// @include btn-border-hover(是否生成 Icon 相關樣式);
			@include btn-border-hover(true);
		}
	}
}

/* Button */
.button-group {
	@at-root %button-group {
		--margin-x: #{rem-calc(7)};

		margin-right: calc(var(--margin-x) * -1);
		margin-left: calc(var(--margin-x) * -1);

		.button {
			margin-right: var(--margin-x);
			margin-left: var(--margin-x);
		}
	}

	@extend %button-group;

	@include breakpoint(medium) {
		--margin-x: #{rem-calc(15)};
	}

	&-s {
		@extend %button-group;
	}

	// 垂直排列
	&-vertical {
		$el: &;

		margin-right: auto;
		margin-left: auto;
		max-width: rem-calc(230);

		@at-root .reveal #{$el} {
			max-width: 100%;

			@include breakpoint(medium) {
				width: 70%;
				max-width: rem-calc(350);
			}
		}

		.button {
			display: block;
			margin-bottom: em-calc(40 / 20);
			margin-right: auto;
			margin-left: auto;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.button {
	@extend %btn-border-item;

	--color: #{$white};
	--bg-color: #{$primary-black};

	min-width: rem-calc(155);
	font-size: rem-calc(14);
	font-weight: $font-weight-m;
	letter-spacing: 0;
	border-radius: rem-calc(40);

	// 更改 Foundation Hover 基礎顏色
	&:hover,
	&:focus {
		--color: #{$white};
		--bg-color: #{$primary-black};
	}

	// SIT 201 - 按鈕外框沒置中（推測為小數點造成）
	@include breakpoint(small only) {
		padding: rem-calc(12 14);
	}

	@include breakpoint(medium) {
		--space-normal: #{rem-calc(8)};
		--padding-y: #{em-calc(16.5 / 20)};

		padding-top: var(--padding-y);
		padding-bottom: var(--padding-y);
		min-width: rem-calc(230);
		font-size: rem-calc(20);
		border-radius: rem-calc(56);
	}

	// ======
	// [Icon] Arrow
	.be-icon {
		display: inline-block;
	}

	// → 向右的箭頭
	.be-icon-arrow-to-right {
		margin-left: rem-calc(8);

		&::before {
			transform: scale(#{(22 / 14)}) translateY(#{(1 / 14 * 1em)});
			transform: scale(#{(22 / 14)});
			transform-origin: left center;
		}
	}

	.be-icon-play {
		margin-left: em-calc(10 / 20);

		&::before {
			margin: 0;
		}
	}

	&.tiny {
		--padding-y: #{em-calc(9 / 16)};

		min-width: 0;

		@include breakpoint(medium) {
			font-size: rem-calc(16);
		}
	}

	&.small {
		min-width: rem-calc(125);

		@include breakpoint(medium) {
			--padding-y: #{em-calc(8 / 18)};

			font-size: rem-calc(16);
		}

		@include breakpoint(large) {
			min-width: rem-calc(180);
			font-size: rem-calc(18);
		}
	}

	&.border-none,
	&.border-none-dark {
		&::before {
			opacity: 0;
		}

		@include breakpoint-hover {
			--space-normal: 0px; // 一定要有px單位，不然會沒有效果

			&:hover,
			&:focus {
				&::before {
					opacity: 1;
				}
			}
		}
	}

	&.border-none-dark {
		border-color: $primary-black;
	}

	&.black {
		--color: #{$white};
		--bg-color: #{$primary-black};
	}

	&.white {
		--color: #{$primary-black};
		--bg-color: #{$white};

		&.border-none {
			border-color: transparent;
		}

		// 更改 Foundation Hover 基礎顏色
		&:hover,
		&:focus {
			--color: #{$primary-black};
			--bg-color: #{$white};
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				--color: #{$white};
				--bg-color: #{$primary-black};
			}
		}
	}

	&.hollow {
		color: $primary-black;

		&.white {
			color: $white;
		}
	}

	&.transparent {
		--color: #{$primary-black};
		--bg-color: transparent;

		box-shadow: none;

		@include breakpoint-hover {
			&:hover,
			&:focus {
				--color: #{$white};
				--bg-color: #{$primary-black};
			}
		}
	}

	&.disabled,
	&[disabled] {
		// 蓋過 Foundation 原本的設定
		&,
		&:hover,
		&:focus {
			@extend %btn-standard;
		}

		--color: #{$white};
		--bg-color: #{$disabled-color};

		opacity: 1;

		&::before,
		&::after {
			display: none;
		}
	}
}

/* Circle Box | 圓圈 */
.circle-box {
	@extend %btn-border-item;

	--size: #{rem-calc(33)}; // 圓尺寸

	display: flex;
	align-items: center;
	justify-content: center;
	margin: var(--space-normal);
	width: var(--size);
	height: var(--size);
	border-radius: 100%;
	box-sizing: border-box;

	.be-icon {
		font-size: calc(var(--size) * #{(26 / 50)});
	}

	.be-icon-play {
		&::before {
			transform: translateX(#{percentage(3.6179/36.38)});
		}
	}

	// 上下左右同層具有文字
	&.has-text {
		&--bottom {
			margin-bottom: rem-calc(20);
		}

		&--right {
			margin-right: calc(var(--space-normal) + #{rem-calc(10)});
		}
	}

	&.border-none {
		&::before {
			opacity: 0;
		}

		@include breakpoint-hover {
			--space-normal: 0px; // 一定要有單位

			&:hover,
			&:focus {
				&::before {
					opacity: 1;
				}
			}
		}
	}

	@include breakpoint(medium) {
		--size: #{rem-calc(50)}; // 圓尺寸
	}
}

// Circle Box 在前的 Button
.button-circle-box {
	display: inline-flex;
	align-items: center;
	font-size: rem-calc(14);
	font-weight: $font-weight-m;
	cursor: pointer;

	// 更改 Foundation Hover 基礎顏色
	&,
	&:hover,
	&:focus {
		color: $primary-black;
	}

	@include breakpoint-hover {
		&:hover,
		&:focus {
			.circle-box {
				@include btn-border-hover(true);
			}
		}
	}
}

// 文字 + 箭頭
.text-arrow {
	--padding: #{rem-calc(12)};
	--move-x: 0; // 箭頭位移的程度

	display: inline-block;
	padding: var(--padding);
	font-size: rem-calc(18);
	font-weight: bold;
	color: $primary-black;

	&::after {
		@include font-icon-arrow-right;

		transform: translateX(var(--move-x));
		transition: 0.3s;
	}

	// 蓋過 Foundation 設定
	&:hover,
	&:focus {
		color: $primary-black;
	}

	@include breakpoint(medium) {
		font-size: rem-calc(20);
	}

	@include breakpoint-hover {
		&:hover,
		&:focus {
			--move-x: 20px;
		}
	}

	&.reverse {
		&::before {
			@include font-icon-arrow-left;

			margin-right: rem-calc(18);
			margin-left: rem-calc(-6);

			@include breakpoint(large) {
				transform: translateX(calc(var(--move-x) * -1));
				transition: 0.3s;
			}
		}

		&::after {
			display: none;
		}
	}
}

.button-back {
	margin-left: calc(var(--padding) * -1);
	font-weight: $font-weight-m;

	@include breakpoint(small only) {
		margin-bottom: rem-calc(4);
	}
}

// --------------------------------------------------------

// [START] Box
.shadow-box {
	$root: &;

	--margin-bottom: #{rem-calc(15)};
	--padding-x: #{rem-calc(20)};
	--padding-y: #{rem-calc(40)};

	padding: var(--padding-y) var(--padding-x);
	background-color: $white;
	box-shadow: $box-shadow-panel;

	&:not(:last-child) {
		margin-bottom: var(--margin-bottom);
	}

	@include breakpoint(medium) {
		--margin-bottom: #{rem-calc(30)};
		--padding-x: #{rem-calc(50)};
		--padding-y: #{rem-calc(60)};
	}

	&__cont {
		margin-right: auto;
		margin-left: auto;
		max-width: rem-calc(955);
	}

	&.article-wrapper {
		#{$root} {
			&__cont {
				max-width: rem-calc(1190);
			}
		}

		@include breakpoint(large) {
			margin-bottom: rem-calc(100);
		}

		@include breakpoint(xlarge) {
			--padding-y: #{rem-calc(100)};
		}
	}

	.article {
		margin-top: rem-calc(40);
	}
}

// --------------------------------------------------------

// [START] Tooltip
.has-tip {
	border-bottom: 0;
	outline: 0;
	&:focus {
		outline: 0;
	}
}

.tooltip {
	padding: rem-calc(16);
	max-width: rem-calc(225);
	font-size: rem-calc(14);
	font-family: inherit;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: rem-calc(0.8);
	color: $white;
	background-color: rgba($primary-black, 0.6);
	border-radius: rem-calc(10);

	@include breakpoint(medium) {
		font-size: rem-calc(16);
	}

	&.top::before {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: rem-calc(12 6 0 6);
		border-color: rgba($primary-black, 0.6) transparent transparent transparent;
	}
}
// [END] Tooltip

// --------------------------------------------------------

// Cart
.button-add-notice {
	&.is-active {
		--bg-color: #{$gray-dark1};
	}
}

// --------------------------------------------------------

// 上稿區的文字大小控制
.article {
	// 提升標題大小
	// * H2 => H1 字型大小
	// * H3 => H2 字型大小
	// * H4 => H3 字型大小
	// * H5 => H4 字型大小
	// * H6 => H5 字型大小
	&.heading-up {
		@each $size, $headers in $header-styles {
			@include breakpoint($size) {
				@each $header, $header-defs in $headers {
					// $font-size-temp: 1rem;
					$i: index($headers, $header $header-defs);

					h#{$i + 1},
					.h#{$i + 1} {
						$font-size-temp: rem-calc(map-get($header-defs, font-size));
						font-size: $font-size-temp;
					}
				}
			}
		}
	}

	// 降低標題大小
	// * H2 => H3 字型大小
	// * H3 => H4 字型大小
	// * H4 => H5 字型大小
	// * H5 => H6 字型大小
	// * H6 => H7 字型大小???
	// ※條款的部分基本上都使用這個
	&.heading-down {
		@each $size, $headers in $header-styles {
			@include breakpoint($size) {
				@each $header, $header-defs in $headers {
					// $font-size-temp: 1rem;
					$i: index($headers, $header $header-defs);

					h#{$i - 1},
					.h#{$i - 1} {
						$font-size-temp: rem-calc(map-get($header-defs, font-size));
						font-size: $font-size-temp;
					}
				}
			}
		}
	}

	a {
		color: $primary-black;
		text-decoration: underline;

		&:visited {
			color: $visited-color;
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}
}

// --------------------------------------------------------

/* Help */

.mt-0 {
	margin-top: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.pt-0 {
	padding-top: 0;
}

.pb-0 {
	padding-bottom: 0;
}

// 定義 SVG 隱藏於頁面使用
.svg-def {
	position: absolute;
	top: -9999px;
	left: -9999px;
	overflow: hidden;
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
	speak: never;
}

// 加速效能用
.translate3d {
	transform: translate3d(0, 0, 0);
}

// --------------------------------------------------------

/* Keyframes */

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes wave {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	33.33%,
	100% {
		opacity: 0;
		transform: scale(1.2);
	}
}

// 為了延長 opacity 1 的時間分開設定(SIT 4)
@keyframes arrow-opacity {
	0%,
	20%,
	80%,
	100% {
		opacity: 1;
	}

	50%,
	51% {
		opacity: 0;
	}
}

@keyframes arrow-to-right {
	0%,
	100% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(10px);
	}

	51% {
		transform: translateX(-10px);
	}
}

@keyframes arrow-to-left {
	0%,
	100% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(-10px);
	}

	51% {
		transform: translateX(10px);
	}
}

@keyframes arrow-to-top {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-10px);
	}

	51% {
		transform: translateY(10px);
	}
}

@keyframes arrow-to-bottom {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(10px);
	}

	51% {
		transform: translateY(-10px);
	}
}

@keyframes rotate-hor {
	0%,
	100% {
		transform: rotateY(0);
	}

	50% {
		transform: rotateY(180deg);
	}
}

// Liked Heart Animation
// Ref: https://codepen.io/ritul-daryan/pen/RwVWwRW?editors=0100
@keyframes scale-bounce {
	0% {
		transform: scale(0);
	}

	25% {
		transform: scale(1.3);
	}

	50% {
		transform: scale(1);
	}

	75% {
		transform: scale(1.3);
	}

	100% {
		transform: scale(1);
	}
}

.fade-in-up {
	opacity: 0;

	&.is-animated {
		animation: fade-in-up 0.3s 0.3s forwards;
	}
}

@keyframes fade-in-up {
	0% {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}

	10% {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
