// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：Layout樣式 Loader、Header、Footer

// -------------------------------------------------------

/* Loader */
.loader {
	$root: &;

	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	// background: rgba($white, 0.5);
	cursor: wait;

	&__img {
		span {
			display: inline-block;
			margin: 0 rem-calc(1);
			width: 3px;
			height: 50px;
			background: #ccc;
			background-size: auto 100%;
			border-radius: 9px;
			animation: sound 0.3s linear infinite alternate;

			@for $i from 1 through 10 {
				&:nth-child(#{$i}) {
					animation-delay: -0.1s * ($i - 1);
				}
			}
		}
	}

	&.is-loaded {
		opacity: 0;
		transition: opacity 0.5s 0.5s;
		pointer-events: none;

		#{$root} {
			&__img {
				span {
					background: $primary-black;
					transition: background-color 0.5s;
				}
			}
		}
	}
}

@keyframes sound {
	0% {
		opacity: 0.35;
		transform: scaleY(0.1);
	}

	100% {
		opacity: 1;
		transform: scaleY(1);
	}
}

// -------------------------------------------------------

/* Logo */

.brand-logo {
	--logo-width: #{(196px + 80px)}; // Hover位移用，要實際的單位

	display: block;
	overflow: hidden;
	width: var(--logo-width);

	@at-root %brand-img-wrap {
		position: relative;
		display: block;
	}

	&__logo {
		@extend %brand-img-wrap;

		width: 196px;

		&::before {
			@include before-img-size(1038, 160, true);
		}
	}

	&__year {
		@extend %brand-img-wrap;

		width: 80px;

		&::before {
			@include before-img-size(330, 45, true);
		}
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Header Logo
.hd-logo {
	$root: &;
	$tsd: $logo-tsd; // 動畫時間，收起年份

	--logo-width: #{(160px)};

	@at-root %hd-logo-tran {
		transition: transform $tsd;
	}

	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	align-items: center;
	transform: translate(-50%, -50%);

	&__line {
		@extend %hd-logo-tran;

		display: block;
		flex: 0 0 1px;
		margin-right: percentage(10 / 196);
		margin-left: percentage(10 / 196);
		width: 1px;
		height: 16px; // 100%沒用，flex也沒用
		background: #535353;
		transition-delay: $tsd / 2 * 3;
	}

	.brand-logo {
		&__logo {
			@extend %hd-logo-tran;

			transition-delay: $tsd;

			&:not(.loaded-content) {
				~ #{$root} {
					&__line {
						opacity: 0;
					}
				}
			}
		}

		&__year {
			@extend %hd-logo-tran;

			transform-origin: left center;
			transition-delay: $tsd * 2;
		}
	}

	@include breakpoint(large) {
		--logo-width: #{(196px + 80px + 20px * 2 + 1px)}; // Hover位移用，要實際的單位

		&__line {
			height: 30px; // 100%沒用，flex也沒用
		}
	}
}

// -------------------------------------------------------

/* Header */
$hd-height-s: rem-calc(50);
$hd-height-l: rem-calc(120);
$hd-height-l-collapse: rem-calc(75);

$ft-height-s: rem-calc(469);
$ft-height-m: rem-calc(269);
$ft-height-l: rem-calc(158);

:root {
	--hd-height-fixed: #{$hd-height-s}; // 不會變動 (避免 Header Fixed 後影響)
	--hd-height: #{$hd-height-s};
	--ft-height: #{$ft-height-s};

	@include breakpoint(medium) {
		--ft-height: #{$ft-height-m};
	}

	@include breakpoint(large) {
		--hd-height-fixed: #{$hd-height-l}; // 不會變動 (避免 Header Fixed 後影響)
		--hd-height: #{$hd-height-l};
		--ft-height: #{$ft-height-l};

		&.is-collapse {
			--hd-height: #{$hd-height-l-collapse};
		}
	}

	// 內容區左右Padding
	--padding-x: #{$gutter-s-half};
	--padding-x-global: #{$gutter-s-half}; // 避免被內層的數值影響，保存Global

	@include breakpoint(medium) {
		--padding-x: #{rem-calc(70)};
		--padding-x-global: #{rem-calc(70)}; // 避免被內層的數值影響，保存Global
	}

	@include breakpoint(xlarge) {
		--padding-x: #{rem-calc(100)};
		--padding-x-global: #{rem-calc(100)}; // 避免被內層的數值影響，保存Global
	}
}

.hd {
	$root: &;
	$tsd: $logo-tsd; // 動畫時間，收起年份

	--hd-height: #{$hd-height-s};
	--hd-bg: #{$white};
	--hd-box-shadow: #{$box-shadow-panel};

	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;

	@include breakpoint(large) {
		--padding-y: #{rem-calc(20)}; // 100px
		--padding-top: #{rem-calc(20 + 25)};
		--padding-bottom: #{rem-calc(20 - 5)};
		--hd-height: #{$hd-height-l};
	}

	// 只有首頁是透明的
	&.transparent {
		@include breakpoint(large) {
			--hd-bg: transparent;
			--hd-box-shadow: none;
		}

		~ .off-canvas-content {
			.page-nav {
				--bg: transparent;
				--box-shadow: none;
			}
		}
	}

	&.is-menu-fixed {
		position: fixed;
	}

	&.is-collapse {
		--hd-bg: #{$white};

		@include breakpoint(large) {
			--hd-height: #{$hd-height-l-collapse};
			--hd-box-shadow: #{$box-shadow-panel};
			--padding-top: #{rem-calc(7.5)};
			--padding-bottom: #{rem-calc(7.5)};
		}

		// === Logo 縮起 ===
		.brand-logo {
			&__logo {
				transform: translateX(30px);
				transition-delay: $tsd * 2;

				@include breakpoint(large) {
					transform: translateX(61px);
				}
			}

			&__year {
				transform: scaleX(0);
				transition-delay: $tsd / 2 * 3;
			}
		}

		.hd-logo {
			&__line {
				transform: scaleY(0);
				transition-delay: $tsd;
			}
		}
	}

	@at-root %menu-box-shadow-none {
		--hd-box-shadow: none;
	}

	&.is-menu-open {
		@extend %menu-box-shadow-none;

		--hd-bg: #{$white};

		// position: fixed;

		@include breakpoint(large) {
			#{$root} {
				&__btn {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}

	&.has-page-nav {
		@extend %menu-box-shadow-none;
	}

	// Search Focus
	&.is-search-focus {
		@include breakpoint(medium down) {
			#{$root}-menu {
				// 主選單 & 會員選單隱藏
				&__list {
					display: none;
				}
			}

			.hd-search {
				&__list {
					display: block;
				}

				&__cancel {
					margin-left: rem-calc(15);
					max-width: rem-calc(48);
				}
			}
		}

		@include breakpoint(small only) {
			height: 100%;
			transform: translateY(calc(var(--hd-height) * -1));

			.hd-menu {
				height: 100%;
			}
		}

		@include breakpoint(large) {
			// 主選單 & 會員選單隱藏
			.hd-main {
				opacity: 0;
				pointer-events: none;
			}
		}
	}

	&-main {
		position: relative;
		z-index: 20;
		// background: var(--hd-bg);
		// transition-property: background-color;
		// box-shadow: $box-shadow-panel;

		// 背景
		// UAT: 130, 131 - 因為首頁Header是透明、內頁是有底色，客戶不希望一開始看到首頁有白底，調整整體寫法
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--hd-bg);
			box-shadow: var(--hd-box-shadow);
			opacity: 0;
			animation: fade 0.5s 0.7s forwards;
			transition: 0.5s;
		}

		@include breakpoint(large) {
			padding: var(--padding-top) 0 var(--padding-bottom);
			transition-duration: $hd-tsd;
			transition-property: padding;
		}
	}

	&__cont {
		display: flex;
		justify-content: space-between;

		@include breakpoint(medium down) {
			padding-right: 0;
			padding-left: 0;
		}

		@include breakpoint(large) {
			position: relative;
		}
	}

	&__l {
		@include breakpoint(large) {
			margin-left: rem-calc(-14);
		}
	}

	// Search, Cart, Member 外層
	&__r {
		display: flex;

		@include breakpoint(medium down) {
			margin-right: rem-calc(2);
		}

		@include breakpoint(large) {
			margin-right: rem-calc(-19);
		}

		&__item {
			@include breakpoint(medium down) {
				&.search,
				&.member {
					display: none;
				}
			}

			@include breakpoint(large) {
				margin-left: rem-calc(12);
			}
		}
	}

	&__btn {
		position: relative;
		display: block;
		padding: rem-calc(10);
		width: var(--hd-height);
		font-size: rem-calc(round(24 * 24 / 21)); // 設定24px，但只實際上只顯示21px，以比例轉換
		color: $primary-black;
		cursor: pointer;
		border-bottom: 0; // 蓋過 Foundation Tooltip 套件設定

		&::before {
			@include before-img-size;
		}

		.be-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			pointer-events: none;
		}

		// 調整Cart(v2.以輪子為置中基準的 Icon)到視覺稿的大小
		.be-icon-cart {
			font-size: percentage(136 / 128);

			&::before {
				transform: translate(-4%, -1%);
			}
		}

		// 提醒(購物車數量之類
		&__alert {
			position: absolute;
			top: 31%;
			right: 30%;
			display: block;
			padding: rem-calc(0 4);
			min-width: rem-calc(14);
			font-size: rem-calc(12);
			font-weight: bold;
			line-height: (14 / 12);
			text-align: center;
			color: $white;
			background: $primary-color;
			border-radius: 99px;
			transform: translate(50%, -50%);
		}

		&:hover,
		&:focus {
			color: $primary-black;
		}

		@include breakpoint(large) {
			width: rem-calc(60);
			transition: opacity 0.3s;
		}

		@include breakpoint-hover {
			border-radius: 100%;
			transition: background-color 0.3s;

			&:hover,
			&:focus {
				background: $hover-bg;
			}
		}
	}
}

// Hamburger Menu
.hd-ham {
	$root: &;

	position: relative;
	display: block;
	cursor: pointer;
	width: var(--hd-height);

	@include breakpoint(small only) {
		margin-left: rem-calc(3); // 為了對齊，左側留 15px
	}

	@include breakpoint(large) {
		width: rem-calc(60);
	}

	&::before {
		@include before-img-size(1, 1, true);
	}

	&__svg {
		--size: #{(50px * 24 / 32)}; // 等比縮小

		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		margin-top: calc(var(--size) / 2 * -1);
		margin-left: calc(var(--size) / 2 * -1);
		width: var(--size);
		pointer-events: none;
		transform: rotate(180deg);

		@include breakpoint(large) {
			--size: 50px;
		}
	}

	&__g {
		transition: transform 1s;
	}

	&__path {
		stroke-dasharray: 30 (94 - 30);
		stroke-dashoffset: 94;
		transform-origin: 25px 25px;
		transition-duration: 1s;
		transition-property: stroke-dasharray, stroke-dashoffset;

		&.line-c {
			stroke-dasharray: 30 75;
			stroke-dashoffset: 105;
		}
	}

	// Open
	&.is-menu-open {
		#{$root} {
			&__g {
				transform: translateX(-45px);
			}
		}

		.line-t,
		.line-b {
			stroke-dashoffset: 31;
		}

		.line-c {
			stroke-dashoffset: 15;
			transition-duration: 1.5s;
		}
	}

	@include breakpoint-hover {
		padding: rem-calc(5);
		border-radius: 100%;

		&:hover,
		&:focus {
			background: $hover-bg;
		}
	}
}

// Menu
@mixin hd-menu-a-style {
	--padding-x: #{rem-calc(5)};
	--padding-y: #{rem-calc(9)};

	display: block;
	padding: var(--padding-y) var(--padding-x);
	color: $primary-black;
}

%hd-menu-a-style {
	@include hd-menu-a-style;
}

.hd-menu {
	$root: &;

	ul {
		margin: 0;
		list-style: none;
	}

	a {
		@extend %hd-menu-a-style;
	}

	.grid-container {
		@include breakpoint(medium only) {
			--padding-x: #{rem-calc(50)};
		}
	}

	&__cont {
		opacity: 0;
		animation: fade 0.3s 0.5s forwards;
	}

	@include breakpoint(medium down) {
		position: fixed;
		z-index: 1000;
		top: var(--hd-height);
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		height: calc(100% - var(--hd-height));
		font-size: rem-calc(14);
		background: $white;
		// transform: translate(-100%);

		// 為了蓋掉 Foundation 樣式
		&.is-transition-push {
			z-index: 1000;
		}

		// 第一層
		// 第一層 - <li>
		&__item {
			margin-bottom: rem-calc(20);

			// 沒有下一層（直接連結）
			&:not(.has-sub) {
				margin-bottom: rem-calc(20 - 7);

				#{$root} {
					&__link {
						border-bottom: 0 none;

						&::before {
							--rotate: -45deg;

							right: rem-calc(7 + 5);
						}
					}
				}
			}

			&.is-active {
				#{$root} {
					&__link {
						&::before {
							--rotate: #{(45deg + 180)};
							--dir: 1;
						}
					}
				}
			}

			// 第二層 - 選單收合
			@include toggle-menu-item('.is-active', '.hd-menu__sec__link');
		}

		// 第一層 - 連結(標題)
		&__link {
			position: relative;
			font-size: rem-calc(18);
			font-weight: $font-weight-b;
			border-bottom: 1px solid $primary-black;

			&::before {
				--size: #{rem-calc(15)};
				--border: 1px solid #{$primary-black};
				--rotate: 45deg;
				--dir: -1; // 箭頭方向，1: 向上；-1: 向下

				content: '';
				position: absolute;
				top: 50%;
				right: rem-calc(7);
				margin-top: calc(var(--size) / 3 * var(--dir)); // -5px
				width: var(--size);
				height: var(--size);
				border-right: var(--border);
				border-bottom: var(--border);
				transform: translateY(-50%) rotate(var(--rotate));
				transition: 0.3s;
			}
		}

		// 第二層
		&__sec {
			// 第二層 - 視覺圖
			&__vision {
				display: none;
			}
		}
	}
}

// 主選單(1024+)
.hd-main-menu {
	$el: &;
	$root: '.hd-menu';

	@include breakpoint(large) {
		&-wrapper {
			--height: calc(100vh - var(--hd-height));

			position: absolute;
			top: var(--hd-height);
			left: 0;
			width: 100%;
			height: var(--height);
			background: $white;
			transform: translateY(-130%);
			// 211111: SIT 129: 因為客戶覺得展開收合的漸變動作，會讓畫面一直左右移動（有滾軸／沒滾軸切換） => 移除動畫
			// 211112: SIT 129: 客戶說要把動畫加回來
			transition: transform 0.5s, height 0.5s, top 0.5s;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 50vh;
				transform: translateY(-100%);
				background: $white;
			}

			.grid-container {
				height: 100%;
			}
		}

		// Open
		@at-root #{$root} {
			&.is-menu-open {
				#{$el} {
					&-wrapper {
						transform: translateY(0);
					}
				}
			}
		}

		&-cont {
			position: relative;
			height: 100%;
		}

		// <ul>
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: 100%;

		#{$root} {
			// 第一層
			&__item {
				--title-font-size: #{rem-calc(36 * 0.8)};

				&:last-child {
					#{$root}__link {
						margin-bottom: 0;
					}
				}

				// Active
				&.is-active {
					#{$root} {
						&__link {
							font-weight: $font-weight-b;
							color: $primary-color;

							&::after {
								transform: scaleX(1);
							}
						}

						&__sec {
							display: flex;
							animation: fade 0.5s;
						}
					}
				}
			}

			// 第一層 - 標題
			&__link {
				--padding-x: 0;

				position: relative;
				margin-bottom: em-calc(50 / 36);
				width: em-calc(240 / 36);
				font-size: var(--title-font-size);
				font-weight: $font-weight-m;
				transition: color 0.3s;
				text-transform: uppercase;

				// Hover / Active 線段
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 3px;
					background: currentColor;
					transform: scaleX(0);
					transform-origin: left center;
					transition: transform 0.3s;
				}
			}

			// 第二層
			&__sec {
				--left: calc(var(--title-font-size) * 10);

				position: absolute;
				top: 10%;
				left: var(--left);
				display: none; // Hide
				align-items: center;
				justify-content: space-between;
				width: calc(100% - var(--left));
				height: 80%;

				// <ul>
				&__list {
					flex: 1;
					overflow-y: auto;
					margin-right: rem-calc(20);
					max-height: 560px;
				}

				// <li>
				&__item {
					margin-bottom: (40 / 1080 * 100vh);

					&:last-child {
						margin-bottom: 0;
					}
				}

				// <a>
				&__link {
					// margin-bottom: em-calc(40 / 24);
					font-size: rem-calc(24 * 0.8);
				}

				// 視覺圖
				&__vision {
					position: relative;
					width: 50%;
					height: 100%;

					img {
						position: absolute;
						top: 50%;
						width: 100%;
						max-width: 710px;
						height: 100%;
						max-height: 560px;
						object-fit: cover;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	@include breakpoint(xlarge) {
		#{$root} {
			&__item {
				--title-font-size: #{rem-calc(36)};
			}

			&__sec {
				&__link {
					font-size: rem-calc(24);
				}
			}
		}
	}

	@include breakpoint(1600) {
		#{$root} {
			&__sec {
				&__vision {
					width: percentage(710 / 1075);
				}
			}
		}
	}

	@include breakpoint-hover {
		#{$root} {
			&__link {
				&:hover,
				&:focus {
					&::after {
						transform: scaleX(1);
					}
				}
			}

			&__sec {
				&__link {
					&:hover,
					&:focus {
						font-weight: $font-weight-b;
						color: $primary-color;
					}
				}
			}
		}
	}
}

// 會員選單(1024+)
.hd-mem {
	@include breakpoint(large) {
		// --padding-y: #{rem-calc(25)};
		--padding-y: #{rem-calc(0)}; // Hide

		position: absolute;
		z-index: 1200;
		right: 100px;
		overflow: hidden; // Hide
		padding: var(--padding-y) rem-calc(40);
		min-width: rem-calc(290);
		max-height: 0; // Hide
		font-size: rem-calc(20);
		background: $white;
		opacity: 0; // Hide
		box-shadow: 0 20px 50px 0 rgba($black, 0.15);
		transition-duration: 0.3s;
		transition-property: max-height, padding-top, padding-bottom, opacity;

		&.is-open {
			--padding-y: #{rem-calc(40 - 5)};

			max-height: 400px;
			opacity: 1;
		}

		// 會員選單 - 未登入
		.no-sign-in {
			display: none;
		}

		.hd-menu {
			// Hello Text, Hi, User
			&__link {
				display: none;
			}
		}

		a {
			--padding-x: #{rem-calc(5 + 3)};
			--padding-y: #{rem-calc(8 + 5)};
		}
	}

	@include breakpoint(1440 + 100 * 2) {
		right: calc((100% - 1440px) / 2);
	}

	@include breakpoint-hover {
		.hd-menu {
			&__sec {
				&__link {
					&:hover,
					&:focus {
						color: $primary-color;
					}
				}
			}
		}
	}
}

// Search
.hd-search {
	$root: &;

	--cont-padding-x: #{rem-calc(40)};

	button {
		cursor: pointer;
	}

	// === Input 部分 ===
	&__input-group {
		--padding-x: 0;
		--padding-y: #{rem-calc((50 - 40) / 2)};

		display: flex;
		align-items: center;
		padding: var(--padding-y) var(--padding-x);
	}

	&__input-box {
		position: relative;
		flex: 1;
	}

	&__input {
		margin-bottom: 0;
		padding-right: var(--cont-padding-x, #{rem-calc(8)});
		padding-left: var(--cont-padding-x, #{rem-calc(40)});
	}

	@at-root %hd-search-icon {
		--size: #{rem-calc(40)};
		--pos: 0;

		position: absolute;
		top: 50%;
		width: var(--size);
		height: var(--size);
		transform: translateY(-50%);
	}

	// 送出按鈕 (Search Icon)
	&__button-submit {
		@extend %hd-search-icon;

		left: var(--pos);
		font-size: rem-calc(20);
		color: $primary-black;
	}

	// 清除
	&__clean {
		@extend %hd-search-icon;

		right: var(--pos);
	}

	// 取消(關閉)
	&__cancel {
		flex: 0 0 auto;
		margin-left: rem-calc(15);
		height: rem-calc(40);
		white-space: nowrap;
	}

	// === 快速連結、關鍵字 ===
	&__list {
		--padding-x: #{rem-calc(0)};
		--padding-y: #{rem-calc(9)};

		padding: var(--padding-y) var(--padding-x);

		&__title {
			margin-bottom: em-calc(9 / 14);
			color: $gray-dark1;
		}

		&__group {
			&.suggest {
				display: none;
			}
		}

		a {
			@extend %hd-menu-a-style;

			&.is-selected {
				color: $primary-color;
				background: rgba($primary-black, 0.03);
			}
		}
	}

	&.is-searching {
		#{$root} {
			&__list__group {
				&.quick-link {
					display: none;
				}

				&.suggest {
					display: block;
				}
			}
		}
	}

	@include breakpoint(medium down) {
		margin-bottom: rem-calc(10);

		&__list {
			--padding-x: #{$gutter-s-half};
			--padding-y: #{rem-calc(20)};

			position: absolute;
			top: 55px;
			left: 0;
			display: none; // Hide
			width: 100%;
			height: calc(100% - 55px);
			background: $white;

			a {
				--padding-x: #{rem-calc(0)};
			}
		}

		&__cancel {
			overflow: hidden;
			margin-left: 0;
			max-width: 0;
			transition: all 0.5s;
		}
	}

	@include breakpoint(medium only) {
		&__list {
			--padding-x: #{rem-calc(50)};
		}
	}

	@include breakpoint(large) {
		--cont-padding-x: #{rem-calc(80)};

		position: absolute;
		z-index: 1200;
		top: 0;
		left: 0;
		padding-right: rem-calc(50);
		padding-left: rem-calc(50);
		width: 100%;
		font-size: rem-calc(20);
		background: $white;
		opacity: 0; // Hide
		box-shadow: $box-shadow-panel;
		transform: translateY(-120%); // Hide
		transition-duration: 0.3s;
		transition-property: opacity, transform;

		// Open
		&.is-search-open {
			opacity: 1;
			transform: translateY(0%);

			#{$root} {
				&__list {
					--padding-y: #{rem-calc(25)};

					max-height: 400px; // Hide
					transition-delay: 0.1s;
				}
			}
		}

		&__cont {
			position: relative;
			margin-right: auto;
			margin-left: auto;
			width: 90%;
			max-width: rem-calc(950);
		}

		// === Input 部分 ===
		&__input-group {
			--padding-y: #{rem-calc(20)};
		}

		&__input {
			height: rem-calc(60);
			font-size: inherit;
			letter-spacing: ps-letter-spacing(50);
		}

		@at-root %hd-search-icon-l {
			--size: #{rem-calc(50)};
			--pos: 28px;
		}

		// 送出按鈕 (Search Icon)
		&__button-submit {
			@extend %hd-search-icon-l;

			font-size: rem-calc(28);
		}

		// 清除
		&__clean {
			@extend %hd-search-icon-l;

			font-size: rem-calc(16);
		}

		&__cancel {
			position: absolute;
			right: -10px;
			padding: rem-calc(10);
			transform: translateX(100%);
		}

		// === 快速連結、關鍵字 ===
		&__list {
			--padding-x: var(--cont-padding-x);
			// --padding-y: #{rem-calc(25)};
			--padding-y: #{rem-calc(0)}; // Hide

			position: absolute;
			z-index: 5;
			top: 80px;
			overflow: hidden; // Hide
			width: 100%;
			max-height: 0; // Hide
			background: $white;
			box-shadow: $box-shadow-panel;
			transition-duration: 0.3s;
			transition-property: max-height, padding-top, padding-bottom, opacity;

			ul {
				margin-right: rem-calc(-10);
				margin-left: rem-calc(-10);
			}

			a {
				--padding-x: #{rem-calc(10)};
			}
		}
	}

	@include breakpoint-hover {
		@at-root %hd-search-hover {
			&:hover,
			&:focus {
				background: rgba($primary-black, 0.05);
			}
		}

		@at-root %hd-search-icon--hover {
			border-radius: 100%;
		}

		// 送出按鈕 (Search Icon)
		&__button-submit {
			@extend %hd-search-hover;
			@extend %hd-search-icon--hover;
		}

		// 清除
		&__clean {
			@extend %hd-search-hover;
			@extend %hd-search-icon--hover;
		}

		&__cancel {
			@extend %hd-search-hover;
		}

		&__list {
			a {
				&:hover,
				&:focus {
					color: $primary-color;
				}
			}
		}
	}
}

// --------------------------------------------------------

/* Footer */
.ft {
	position: relative;
	z-index: 100;
	padding-top: rem-calc(35);
	color: $white;
	background: #1b1b1b;

	@include breakpoint(medium down) {
		padding-bottom: rem-calc(30);
	}
}

// Footer 固定在頁面右下的物件
.ft-fix-box {
	$root: &;

	position: fixed;
	z-index: 2000;

	// [START]原版（配合 FB Message Icon 前）
	// bottom: 15px;
	// right: 15px;

	// @include breakpoint-between(medium, large) {
	// 	right: 11px;
	// }

	// @include breakpoint(xlarge) {
	// 	bottom: 20px;
	// 	right: (100px - (50 + 5 * 2)) / 2;
	// }
	// [END] 原版

	// [START] 新版（配合 FB Message Icon）
	// 因為使用FB聊天系統，預設樣式不好調整 → 整個上移
	right: 24px;
	bottom: 100px;
	// [END] 新版（配合 FB Message Icon）

	&__item {
		margin-top: rem-calc(25);
		transition: 0.3s;

		@include breakpoint(xlarge) {
			margin-top: rem-calc(30);
		}
	}

	.circle-box {
		// @include breakpoint-between(medium, large) {
		// 	--size: #{rem-calc(38)};
		// }

		--size: 50px; // （配合 FB Message Icon 60px，含外框60px）
	}

	// 內容物件
	.go-top-wrapper {
		max-height: 50px;
	}

	// 最上方 => 隱藏 Go Top
	&.is-top {
		.go-top-wrapper {
			overflow: hidden;
			margin-top: 0;
			max-height: 0;
			opacity: 0;
		}
	}

	// 不同狀態 ======
	// 產品比較選單 - 顯示
	// @at-root html.compare-show {
	// 	#{$root} {
	// 		&__item {
	// 			transform: translateY(-76px);

	// 			@include breakpoint(medium only) {
	// 				transform: translateY(-50px);
	// 			}
	// 		}
	// 	}
	// }

	// // 產品比較選單 - 隱藏於下方
	// @at-root html.compare-show {
	// 	&.compare-collapse {
	// 		#{$root} {
	// 			&__item {
	// 				transform: translateY(-26px);

	// 				@include breakpoint(medium) {
	// 					transform: translateY(0);
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	// // 產品比較選單 - 開啟
	// @at-root html.compare-show {
	// 	&.compare-open {
	// 		#{$root} {
	// 			&__item {
	// 				@include breakpoint(large) {
	// 					transform: translateY(-144px);
	// 				}

	// 				@include breakpoint(xxlarge) {
	// 					transform: translateY(-189px);
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

// Footer 上半
.ft-top {
	@include breakpoint(small only) {
		padding-right: rem-calc(20);
		padding-left: rem-calc(20);
	}

	@include breakpoint(large) {
		display: flex;
		justify-content: space-between;
	}

	// Footer 上半 - 左側 (SNS, 訂閱)
	&__l {
		@include breakpoint(small only) {
			margin-bottom: rem-calc(24);
		}

		@include breakpoint(medium only) {
			margin-bottom: rem-calc(20);
		}

		@include breakpoint(medium) {
			display: flex;
		}

		@include breakpoint(large only) {
			flex: 0 0 auto;
		}

		&__cell {
			@include breakpoint(small only) {
				margin-bottom: rem-calc(20);
				text-align: center;
			}

			@include breakpoint(medium) {
				display: flex;
				align-items: center;
			}

			@include breakpoint(medium) {
				&:not(:last-child) {
					margin-right: rem-calc(60);
				}
			}

			@include breakpoint(medium only) {
				&:not(:last-child) {
					min-width: calc(50% - 50px);
				}
			}
		}

		// 小標
		&__subtitle {
			font-size: rem-calc(12);
			font-weight: bold;
			letter-spacing: ps-letter-spacing(100);
			white-space: nowrap;

			@include breakpoint(small only) {
				margin-bottom: rem-calc(11);
			}

			@include breakpoint(medium) {
				margin-right: rem-calc(20);
			}
		}

		&__list {
			--margin-x: #{rem-calc(8)};

			display: flex;
			margin: 0 calc(var(--margin-x) * -1);
			list-style: none;

			@include breakpoint(small only) {
				justify-content: center;
				margin-bottom: rem-calc(13);
			}
		}

		&__item {
			display: inline-block;
			margin-right: var(--margin-x);
			margin-left: var(--margin-x);
		}
	}

	&__r {
		@include breakpoint(large only) {
			flex: 1 1 0;
			margin-left: rem-calc(50);
		}
	}
}

// Footer 上的圓圈
.ft-circle-box {
	--size: #{rem-calc(40)};

	position: relative;
	display: block;
	width: var(--size);
	height: var(--size);
	color: $white;
	border: 1px solid $border-color;
	border-radius: 100%;
	box-shadow: 0 0 rem-calc(24) 0 rgba($black, 0.08);

	.be-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: rem-calc(30);
		transform: translate(-50%, -50%);
	}

	&:hover,
	&:focus {
		color: $white;
		background: transparent;
	}

	// Hover
	@include breakpoint-hover {
		// Hover 後的外框
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 1px solid $border-color;
			border-radius: 100%;
			opacity: 0;
			transition: transform 0.3s, opacity 0.3s;
		}

		&:hover,
		&:focus {
			color: $primary-black;
			background: $white;
			border-color: $white;
			transition: 0.3s;

			&::before {
				opacity: 1;
				transform: scale(1.3);
			}
		}
	}
}

// ========================

// 語言區域選單
.ft-area-lang {
	display: flex;
	flex-wrap: wrap;
	margin-right: rem-calc(-10);
	margin-left: rem-calc(-10);

	@include breakpoint(medium down) {
		margin-bottom: rem-calc(15 + 10);
	}

	@include breakpoint(large only) {
		flex: 0 0 auto;
	}
}

// 語言區域選單 - 單項目
.ft-select-box {
	--margin-x: #{rem-calc(10)};

	// margin-bottom: rem-calc(10);
	margin-right: var(--margin-x);
	margin-left: var(--margin-x);
	width: calc(50% - #{rem-calc(10 * 2)});
	text-align: left;

	@include breakpoint(medium) {
		position: relative;
	}

	@include breakpoint(large only) {
		flex: 1;
		width: calc(50% - var(--marginx-x));
	}

	@include breakpoint(xlarge) {
		width: rem-calc(200);
	}

	&__button {
		@include select-style(true);

		--padding-x: #{rem-calc(10)};
		--padding-y: #{rem-calc(7)};

		overflow: hidden;
		padding: rem-calc(7 10);
		padding: var(--padding-y) var(--padding-x);
		width: 100%;
		font-size: rem-calc(14);
		text-align: left;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: $white;
		border: 1px solid rgba($white, 0.5);
		cursor: pointer;

		@include breakpoint(medium) {
			--padding-x: #{rem-calc(20)};
			--padding-y: #{rem-calc(14)};
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				color: $primary-black;
				background-color: $white;
				background-image: url('#{$select-arrow}');
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		background: rgba($primary-black, 0.8);
		border: $dropdown-border;
		list-style: none;

		@include breakpoint(small only) {
			width: calc(100vw - #{rem-calc((15 + 20) * 2)});
		}

		@include breakpoint(medium) {
			width: auto;
			min-width: 100%;
		}
	}

	&__link {
		display: block;
		padding: rem-calc(7 10);
		font-size: rem-calc(14);
		color: $white;

		// &.is-active {
		// 	background: $primary-color;
		// 	pointer-events: none;
		// }

		@include breakpoint(medium) {
			padding-right: rem-calc(20);
			padding-left: rem-calc(20);
			white-space: nowrap;
		}

		&:hover,
		&:focus {
			color: $white;
			background: rgba($white, 0.3);
		}
	}
}

// ========================

// Footer 下半
.ft-bottom {
	margin-top: rem-calc(16);
	padding-top: rem-calc(14);
	border-top: 1px solid rgba($white, 0.15);

	@include breakpoint(large) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: rem-calc(29);
		padding-top: rem-calc(0);
		min-height: rem-calc(60);
	}
}

// Footer 下半 - 左邊 - 連結
.ft-bottom-link {
	margin-left: 0;
	list-style: none;

	@include breakpoint(small only) {
		text-align: center;
	}

	@include breakpoint(medium down) {
		text-align: center;
	}

	@include breakpoint(large) {
		margin-bottom: 0;
		margin-right: rem-calc(-20);
		margin-left: rem-calc(-20);
	}

	&__item {
		@include breakpoint(medium) {
			display: inline-block;
			margin-right: rem-calc(10);
			margin-left: rem-calc(10);
		}
	}

	&__link {
		display: block;
		padding-top: rem-calc(6);
		padding-bottom: rem-calc(6);
		font-size: rem-calc(12);
		text-decoration: underline;
		letter-spacing: ps-letter-spacing(100);
		color: $white;

		&:hover,
		&:focus {
			color: $white;
		}

		@include breakpoint(large) {
			padding: rem-calc(10);
		}

		@include breakpoint-hover {
			transition: 0.3s;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}
}

// Copyright
.ft-copyright {
	font-size: rem-calc(12);
	text-align: center;
	color: #707070;

	@include breakpoint(small only) {
		--padding-x: #{rem-calc(43 + 10)};

		padding: 0 var(--padding-x);
	}
}

// 假的 FB Msg 位置示意
.fb-msg-fake {
	bottom: 24px;
	margin: 0px 12px;
	display: block;
	padding: 0px;
	position: fixed;
	top: auto;
	z-index: 99999;
	border-radius: 30px;
	box-shadow: rgba($black, 0.15) 0px 4px 12px 0px;
	height: 60px;
	width: 60px;
	right: 12px;
	cursor: pointer;
}

// // 真的 FB Msg (要調整到比 Menu 低)
// .fb_dialog {
// 	z-index: 999 !important; // 要蓋過 FB JS 設定，所以加 !important
// }

@include breakpoint(small only) {
	.search-input-is-focus {
		.fb-msg-fake,
		.fb_dialog {
			opacity: 0 !important;
			pointer-events: none !important;
		}
	}
}

// -----------------------------------------------

/* Cookie box */
.notification-box {
	$root: &;

	position: fixed;
	z-index: 15000;
	bottom: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 0 10px rgba($black, 0.2);
	animation: cookieToShow 1s;

	&__cont {
		position: relative;
		padding-top: rem-calc(30 - 3);
		padding-bottom: rem-calc(30);
		color: $white;

		// Bg
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 50%;
			width: 100vw;
			height: 100%;
			background: rgba($primary-black, 0.8);
			transform: translateX(-50%);
		}
	}

	&.is-hide {
		display: none;
	}

	&.to-hide {
		animation: cookieToHide 0.5s forwards;
	}

	.button {
		margin-bottom: 0;
	}

	.close-button {
		top: rem-calc(15 - 3);
		color: $white;
	}

	p {
		color: #a8a7a6;
	}

	@include breakpoint(medium down) {
		font-size: rem-calc(14);

		&__cont {
			padding-right: rem-calc(15);
			padding-left: rem-calc(15);
		}

		&__text-box {
			margin-bottom: rem-calc(20);
		}

		&__btn-box {
			--margin-x: #{rem-calc(7)};

			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 calc(var(--margin-x) * -1);
			text-align: center;
		}

		.button {
			margin: 0 var(--margin-x);
		}
	}

	@include breakpoint(small only) {
		font-size: rem-calc(12);

		.button {
			font-size: rem-calc(12);
			min-width: calc(50vw - #{rem-calc(15 + 14 / 2)});
		}
	}

	@include breakpoint(large) {
		&__cont {
			display: flex;
			align-items: center;
		}

		&__text-box {
			flex: 1 1 0;
			margin-right: rem-calc(20);

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@include breakpoint(large only) {
		&__btn-box {
			display: flex;
			flex-direction: column;
		}

		.button {
			&:not(:last-child) {
				margin-bottom: rem-calc(16);
			}
		}
	}

	@include breakpoint(xlarge) {
		.button {
			&:not(:last-child) {
				margin-right: rem-calc(50);
			}
		}

		.close-button {
			top: rem-calc(20);
			right: rem-calc(12);
		}
	}

	@include breakpoint-hover {
		.button {
			&,
			&.hollow {
				&:hover,
				&:focus {
					--color: #{$primary-black};
					--bg-color: #{$white};
				}
			}

			&.hollow {
				&:hover,
				&:focus {
					color: var(--color);
					background: var(--bg-color);
				}
			}
		}

		.close-button {
			transition: transform 0.3s;

			&:hover,
			&:focus {
				transform: rotate(90deg);
			}
		}
	}

	@keyframes cookieToShow {
		0% {
			transform: translateY(100%);
		}
	}

	@keyframes cookieToHide {
		100% {
			transform: translateY(100%);
		}
	}
}
// END Cookie box
