.custom-select {
	$root: &;

	&__select {
		@include select-style;

		padding-left: rem-calc(8);
		height: rem-calc(44);
		font-size: rem-calc(16);
		border-radius: 0;
		border: 1px solid #7f7f7f;

		&::after {
			display: none;
		}

		&:focus {
			border-color: #000;
		}

		@include breakpoint-hover {
			transition: border-color 0.3s;

			&:hover {
				border-color: #000;
			}
		}
	}

	&__option {
		padding: rem-calc(7 8);
		font-size: rem-calc(14);

		&[disabled] {
			pointer-events: none;
		}

		&:hover,
		&:focus {
			background: transparent;
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				background: var(--hover-bg-color);
			}
		}

		&--selected {
			color: var(--active-color);
			background: var(--active-bg-color);
			cursor: default;

			&[disabled] {
				background: #eee;
			}

			@include breakpoint-hover {
				&:hover,
				&:focus {
					color: var(--active-color);
					background: var(--active-bg-color);
				}
			}
		}
	}

	&__dropdown {
		@include custom-scroll-bar(#cccccc);

		top: 100%;
		margin-left: 0;
		margin-bottom: 0;
		max-height: rem-calc(35 * 6);
		overflow-y: auto;
		border: 1px solid #b2b2b2;
		border-top-width: 0px;
		border-radius: 0;
		box-shadow: 0 2px 15px 0 rgba($black, 0.1);
		cursor: auto;

		@include breakpoint(medium) {
			max-height: rem-calc(35 * 10);
		}
	}

	&--active {
		z-index: 10000;

		#{$root} {
			&__select {
				border-color: #000;
			}

			&__option {
				// 顯示項目
				&--value {
					border-radius: 0;
				}
			}
		}
	}

	&--dropup {
		#{$root} {
			&__dropdown {
				bottom: 100%;
				border-top-width: 1px;
				border-bottom-width: 0px;
				border-radius: 0;
				box-shadow: 0 -2px 15px 0 rgba($black, 0.1);
			}
		}
	}
}
