/*
 * Custom Select jQuery Plugin Theme
 */

$white: #fff !default;

.#{$custom-select-block} {
	$_gutter: 0.75rem;
	$_color: #212121;

	// === &__value ，為了把 option 分開
	&__select {
		position: relative;
		padding: 0.375rem $_gutter;
		padding-right: $_gutter * 2 + 0.375rem;
		width: 100%;
		font-size: 1rem;
		line-height: 1.5;
		text-align: left;
		color: $_color;
		background-color: $white;
		border: 1px solid darken($white, 10%);
		border-radius: 0.25rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		display: block;
		display: -webkit-box;
		overflow: hidden;

		&::after {
			position: absolute;
			box-sizing: border-box;
			width: 0;
			height: 0;
			top: 50%;
			right: $_gutter;
			border-width: 0.3rem;
			border-bottom-width: 0;
			border-style: solid;
			border-color: currentColor transparent;
			transform: translateY(-50%);
			content: '';

			.#{$custom-select-block}--active & {
				transform: translateY(-50%) rotate(-180deg);
			}
		}
	}

	&__option {
		position: relative;
		padding: 0.375rem $_gutter;
		font-size: 1rem;
		color: $_color;
		line-height: 1.5;

		&:focus {
			outline: none;
		}

		&:hover,
		&:focus {
			background-color: darken($white, 2.5%);
		}

		&--value {
			// padding-right: $_gutter * 2 + 0.375rem;
			// background-color: $white;
			// border: 1px solid darken($white, 10%);
			// border-radius: 0.25rem;

			// &::after {
			// 	position: absolute;
			// 	box-sizing: border-box;
			// 	width: 0;
			// 	height: 0;
			// 	top: 50%;
			// 	right: $_gutter;
			// 	border-width: 0.3rem;
			// 	border-bottom-width: 0;
			// 	border-style: solid;
			// 	border-color: currentColor transparent;
			// 	transform: translateY(-50%);
			// 	content: '';

			// 	.#{$custom-select-block}--active & {
			// 		transform: translateY(-50%) rotate(-180deg);
			// 	}
			// }

			.#{$custom-select-block}--active & {
				border-bottom-color: transparent;
				border-radius: 0.25rem 0.25rem 0 0;

				&:hover,
				&:focus {
					background-color: $white;
				}

				@at-root {
					.#{$custom-select-block}--dropup#{&} {
						border-top-color: transparent;
						border-bottom-color: darken($white, 10%);
						border-radius: 0 0 0.25rem 0.25rem;
					}
				}
			}
		}

		&--selected {
			background-color: darken($white, 1.25%);
		}

		&[disabled] {
			color: lighten($_color, 50%);
			cursor: default;

			&:hover,
			&:focus {
				background-color: transparent;
			}
		}
	}

	&__option-wrap {
		overflow-y: auto;
		max-height: (0.375rem * 2 + 1rem * 1.5) * 5;

		&::-webkit-scrollbar {
			width: 16px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: darken($white, 10%);
			background-clip: padding-box;
			border-width: 0 4px;
			border-style: solid;
			border-color: transparent;
		}
	}

	&__input {
		position: relative;
		z-index: 1;
		height: 2.25rem;
		margin-top: -1px;
		padding: 0 $_gutter;
		border-color: darken($white, 10%);
		transform: translateY(1px);
		font-size: 1rem;
		color: $_color;

		&:focus {
			outline: none;
		}

		.#{$custom-select-block}--dropup & {
			border-top-width: 0;
			margin-top: 0;
			transform: translateY(0);
		}
	}

	&__dropdown {
		overflow: hidden;
		z-index: 1;
		top: calc(100% - 1px);
		background-color: $white;
		border-width: 0 1px 1px;
		border-style: solid;
		border-color: darken($white, 10%);
		border-radius: 0 0 0.25rem 0.25rem;

		.#{$custom-select-block}--dropup & {
			bottom: calc(100% - 1px);
			border-width: 1px 1px 0;
			border-radius: 0.25rem 0.25rem 0 0;
		}
	}
}

.#{$custom-select-block}-hide-a11y {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}
