#toast-container {
	top: calc(var(--hd-height) + #{rem-calc(10)});
	transition: top 0.3s;

	@at-root html.has-page-nav & {
		top: calc(var(--hd-height) + #{rem-calc(71)});

		@include breakpoint(large) {
			top: calc(var(--hd-height) + #{rem-calc(80)});
		}
	}

	// .toast
	// 因為原套件這麼寫，蓋過原本套件的寫法
	> div {
		--icon-color: #{$white};
		--icon-bg: #{$black};
		--width: #{480px + 2};

		padding: 0;
		width: var(--width);
		max-width: calc(100vw - #{rem-calc(15 * 2)});
		background: 0 0;
		border-radius: rem-calc(10);
		opacity: 1;
		box-shadow: none;
		text-align: center;

		&:hover,
		&:focus {
			box-shadow: none;

			.toast-message {
				box-shadow: $box-shadow-primary-hover;
			}
		}
	}

	.toast-message {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: rem-calc(11.5 16);
		background-color: rgba($black, 0.6);
		border-radius: rem-calc(10);
		box-shadow: none;
		box-sizing: border-box;
		opacity: 1;
		transition: 0.3s;
	}

	// Icon
	// 2021/10/15，因為客戶一直反映沒有置中，所以直接將圓與Icon合併，直接在圖上置中
	.be-icon {
		--size: #{rem-calc(20)};

		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 var(--size);
		width: var(--size);
		height: var(--size);
		margin-right: rem-calc(6);
		// font-size: em-calc(12 / 16); // 設最小字12
		font-size: var(--size);
		letter-spacing: 0;
		color: var(--icon-bg);
		// background: var(--icon-bg);
		border-radius: 50%;

		&::before {
			// 因為設計稿是9px，但 Chrome 的最小字是 12，如果小於 12 會不改變大小產生位移，讓置中有問題
			// 所以使用 scale 縮成 9 的尺寸
			// transform: scale(#{(9 / 12)});
		}

		// ICON 顏色（白色）
		&::after {
			--size: calc(100% - 2px);

			content: '';
			position: absolute;
			z-index: -1;
			top: 1px;
			left: 1px;
			width: var(--size);
			height: var(--size);
			border-radius: 100%;
			background: var(--icon-color, #fff); // ICON 顏色（白色）
		}
	}

	@at-root %toast-global {
		background-image: none !important;
	}

	@mixin toast-status($bg: '', $color: '') {
		@extend %toast-global;

		--icon-color: #{$color};
		--icon-bg: #{$bg};
	}

	.toast-success {
		@include toast-status($status-success);
	}

	.toast-error {
		@include toast-status($status-error);
	}

	.toast-info {
		@include toast-status($white, $black);
	}

	.toast-warning {
		@include toast-status($status-warning);
	}

	// Progress
	// 因為寬度是JS計算的，所以會有時差，用動畫遮掉
	.toast-progress {
		z-index: 1;
		background: $white;
		opacity: 0;
		animation: toast-progress-fade 0.3s 0.1s forwards;
	}

	@keyframes toast-progress-fade {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 0.5;
		}
	}

	&.toast-bottom-center,
	&.toast-top-center {
		> div {
			width: var(--width);
		}
	}
}
