/*
 * Custom Select jQuery Plugin Base
 */

$custom-select-block: 'custom-select' !default;

.#{$custom-select-block} {
	position: relative;

	&__option {
		overflow: hidden;
		box-sizing: border-box;
		display: block;
		width: 100%;
		padding: 0;
		background-color: transparent;
		border: 0;
		border-radius: 0;
		font-family: inherit;
		// white-space: nowrap;
		text-align: left;
		// text-overflow: ellipsis;
		cursor: pointer;
		user-select: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	&__option-wrap {
		position: relative;
	}

	&__input {
		box-sizing: border-box;
		display: block;
		width: 100%;
		padding: 0;
		border-width: 1px 0;
		border-style: solid;
		border-radius: 0;
		font-family: inherit;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	&__dropdown {
		position: absolute;
		box-sizing: border-box;
		width: 100%;
		top: 100%;
		left: 0;

		.#{$custom-select-block}--dropup & {
			top: auto;
			bottom: 100%;
		}
	}
}
