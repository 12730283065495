.table-wrapper {
	@include breakpoint(small only) {
		position: relative;

		// 滾動提示遮罩
		$gradient-start: #f1f1f1;
		$gradient-end: #f5f5f5;

		&::before,
		&::after {
			--pos: -1px;
			--gradient-color: #{hex-to-rgb($gradient-start)};

			content: '';
			position: absolute;
			z-index: 5;
			top: 0;
			display: none;
			width: #{rem-calc(100)};
			height: 100%;
			background: linear-gradient(
				to right,
				rgba(var(--gradient-color), 0) 0%,
				rgba(var(--gradient-color), 1) 100%
			);
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.3s;
		}

		// 因為這個專案背景是漸層，所以左右抓的顏色不一樣
		&::after {
			--gradient-color: #{hex-to-rgb($gradient-end)};
		}

		// 左遮罩
		&::before {
			left: var(--pos);
			transform: scale(-1);
		}

		// 右遮罩
		&::after {
			right: var(--pos);
		}

		&.has-scroll {
			&::before,
			&::after {
				display: block;
			}
		}

		&:not(.is-scroll-start) {
			&::before {
				opacity: 1;
			}
		}

		&:not(.is-scroll-end) {
			&::after {
				opacity: 1;
			}
		}

		&__scroll-area {
			overflow: auto;
		}

		table {
			width: rem-calc(640 - 15 * 2);
		}
	}

	@include breakpoint(small only) {
		// 滾動提示遮罩
		&::before,
		&::after {
			width: #{rem-calc(100)};
		}
	}
}

@include breakpoint(small only) {
	// 手機板破格的排版方式（需要配合 data-title 顯示標題）
	// 加在 table
	.table-collapse {
		thead {
			display: none;
		}

		tr {
			display: block;
			padding-top: rem-calc(8 + 10);
			padding-bottom: rem-calc(10 + 10);
		}

		tbody {
			tr {
				&:first-child {
					border-top: 1px solid $primary-black;
				}
			}
		}

		th,
		td {
			display: block;
			line-height: 1.2;

			&[data-title] {
				&::before {
					content: attr(data-title);
					display: block;
					margin-bottom: rem-calc(4);
					color: $primary-color;
				}
			}
		}
	}

	// 標題會浮在前方的樣式加在
	// table
	// 不用 &.table-title-absolute 避免 CSS 層級過高，內頁樣式不好改
	// .table-title-absolute {
	// 	th,
	// 	td {
	// 		--padding-left: 5em;

	// 		position: relative;
	// 		padding-left: var(--padding-left);

	// 		&::before {
	// 			position: absolute;
	// 			top: 0.5em;
	// 			left: rem-calc(15);
	// 			width: calc(var(--padding-left) - #{rem-calc(15 * 2)});
	// 			word-break: break-all;
	// 			word-break: break-word;
	// 		}
	// 	}
	// }

	// 強調第一格，將第一格轉為綠色
	// .table-em-first {
	// 	tr {
	// 		margin-bottom: rem-calc(10);
	// 		padding-top: 0;
	// 	}

	// 	th,
	// 	td {
	// 		&:first-child {
	// 			margin-bottom: rem-calc(18);
	// 			padding: rem-calc(14 15);
	// 			line-height: 1;
	// 			color: $white;
	// 			background: $primary-gradient;

	// 			&[data-title] {
	// 				&::before {
	// 					display: inline-block;
	// 					margin-bottom: 0;
	// 					margin-right: rem-calc(10);
	// 					font-weight: bold;
	// 					color: $white;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}
